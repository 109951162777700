import { useLayoutEffect } from 'react';

import { throttle } from '../utils';

export function useResizeEffect(ref, effect) {
  const fn = throttle(effect);

  useLayoutEffect(() => {
    const el = ref === window ? ref : ref?.current;
    if (!el || typeof fn !== 'function') return;

    if (el === window) {
      window.addEventListener('resize', fn);
      return () => {
        window.removeEventListener('resize', fn);
        fn?.cancel?.();
      };
    } else if (window.ResizeObserver) {
      let animationFrame;
      const observer = new ResizeObserver((entries) => {
        animationFrame = window.requestAnimationFrame(() => fn(entries?.[0]));
      });
      observer.observe(el);
      return () => {
        window.cancelAnimationFrame(animationFrame);
        observer.disconnect();
        fn?.cancel?.();
      };
    } else {
      fn(el);
      return () => fn?.cancel?.();
    }
  }, [ref]);
}
