import { useResizeEffect, useScrollEffect } from '../../hooks';
import { Box, useMergeRefs } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import React, { useRef, useState, useCallback, useLayoutEffect } from 'react';

export const Scrollable = forwardRef(
  (
    {
      direction = 'vertical',
      showGradient = true,
      hideScrollbars = true,
      children,
      ...props
    },
    ref,
  ) => {
    const containerRef = useRef(null);
    const refs = useMergeRefs(containerRef, ref);
    const [scrollInfo, setScrollInfo] = useState({});

    const updateScrollable = useCallback(() => {
      if (!ref.current) return;
      setScrollInfo(getScrollInfo(ref.current, direction));
    }, [ref, direction]);

    useLayoutEffect(updateScrollable, [ref, direction]);

    useResizeEffect(ref, updateScrollable);
    useScrollEffect(ref, updateScrollable);

    const { hasScrollableContent, scrollPosition, scrollSize } = scrollInfo;

    return (
      <Box
        width="100%"
        height="100%"
        position="relative"
        _before={
          showGradient && scrollPosition > 0
            ? gradientForDirection('before', direction)
            : null
        }
        _after={
          scrollPosition === scrollSize ||
          !hasScrollableContent ||
          !showGradient
            ? null
            : gradientForDirection('after', direction)
        }
        {...props}
      >
        <Box
          ref={refs}
          overflow="auto"
          width="100%"
          height={direction === 'vertical' ? '100%' : null}
          borderRadius={hideScrollbars ? null : 'base'}
          border={hasScrollableContent && !hideScrollbars ? '1px' : null}
          borderColor={hideScrollbars ? null : 'gray.300'}
          py={hasScrollableContent && !hideScrollbars ? '2' : null}
          px={hasScrollableContent && !hideScrollbars ? '3' : null}
          _focusVisible={
            hideScrollbars
              ? null
              : {
                  borderColor: 'purple.500',
                }
          }
          tabIndex={!hideScrollbars && hasScrollableContent ? 0 : null}
          role={!hideScrollbars && hasScrollableContent ? 'region' : null}
          sx={
            hideScrollbars
              ? {
                  /* Chrome, Safari, Opera */
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  msOverflowStyle: 'none' /* Internet Explorer, Edge */,
                  scrollBarWidth: 'none' /* Firefox */,
                }
              : null
          }
        >
          {children}
        </Box>
      </Box>
    );
  },
);

function gradientForDirection(side, direction) {
  const gradientSize = '20px';
  const gradientProps = {
    vertical: {
      before: {
        position: {
          top: 0,
        },
        direction: 'bottom',
        width: '100%',
        height: gradientSize,
      },
      after: {
        position: {
          bottom: 0,
        },
        direction: 'top',
        width: '100%',
        height: gradientSize,
      },
    },
    horizontal: {
      before: {
        position: {
          top: 0,
          left: 0,
        },
        direction: 'right',
        width: gradientSize,
        height: '100%',
      },
      after: {
        position: {
          top: 0,
          right: 0,
        },
        direction: 'left',
        width: gradientSize,
        height: '100%',
      },
    },
  };
  return {
    content: "' '",
    position: 'absolute',
    pointerEvents: 'none',
    zIndex: 1,
    background: `linear-gradient(to ${gradientProps[direction][side].direction}, white, transparent 99%)`,
    ...gradientProps[direction][side].position,
    width: gradientProps[direction][side].width,
    height: gradientProps[direction][side].height,
  };
}

function getScrollInfo(el, direction) {
  return {
    scrollSize: getScrollSize(el, direction),
    scrollPosition: getScrollPosition(el, direction),
    hasScrollableContent: checkHasScrollableContent(el, direction),
  };
}

function getScrollSize(el, direction) {
  if (!el) return;
  const { scrollHeight, scrollWidth } = el;
  return direction === 'horizontal' ? scrollWidth : scrollHeight;
}

function getScrollPosition(el, direction) {
  if (!el) return;
  const { scrollLeft, scrollTop } = el;
  return direction === 'horizontal' ? scrollLeft : scrollTop;
}

function checkHasScrollableContent(el, direction) {
  if (!el) return;
  const { scrollHeight, scrollWidth, clientHeight, clientWidth } = el;
  return direction === 'horizontal'
    ? scrollWidth > clientWidth
    : scrollHeight > clientHeight;
}
