import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const UserOutlineIcon = (props) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 4c1.092 0 2.027.39 2.807 1.17.78.78 1.17 1.73 1.17 2.853 0 1.092-.39 2.028-1.17 2.807-.78.78-1.715 1.17-2.807 1.17-1.092 0-2.027-.39-2.807-1.17-.78-.78-1.17-1.715-1.17-2.807 0-1.122.39-2.074 1.17-2.853C9.973 4.39 10.908 4 12 4zm0 2.012a1.98 1.98 0 00-1.45.608c-.375.374-.562.842-.562 1.403 0 .53.187.998.562 1.404.405.374.889.561 1.45.561.561 0 1.03-.187 1.403-.561.406-.406.609-.873.609-1.404a1.83 1.83 0 00-.609-1.403A1.83 1.83 0 0012 6.012zm0 6.97c.998 0 2.09.156 3.275.468 1.341.343 2.417.78 3.228 1.31.998.655 1.497 1.404 1.497 2.246V20H4v-2.994c0-.842.499-1.59 1.497-2.246.811-.53 1.887-.967 3.228-1.31 1.185-.312 2.277-.467 3.275-.467zm0 1.919c-.936 0-1.902.124-2.9.374-.874.25-1.622.546-2.246.889-.624.343-.936.623-.936.842v1.076h12.164v-1.076c0-.218-.312-.5-.936-.842-.624-.343-1.372-.64-2.245-.89A11.94 11.94 0 0012 14.902z"
      fill="currentColor"
    />
  </Icon>
);

UserOutlineIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
