import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const FireHydrantIcon = (props) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props}>
    <path d="M11.3322 15.7591L9.888 10.862L6.70886 14.2051L5.48006 9.98917L13.8267 1.2002C4.09344 1.2002 0.693888 5.81971 1.61856 9.67161L3.4537 16.9069C4.416 20.308 0.946176 22.0867 0 22.4853C1.83475 22.5348 3.27821 22.44 4.55462 22.0448C7.01453 21.3521 8.63501 19.6575 11.3322 15.7591Z" fill="#536685" />
    <path opacity="0.8" d="M20.5463 6.79061C19.584 3.38952 23.0538 1.61084 24 1.21225C22.1653 1.16271 20.7218 1.25756 19.4454 1.65269C16.9878 2.3462 15.365 4.0404 12.6682 7.93876L14.1143 12.8359L17.2938 9.49319L18.5226 13.7087L10.1737 22.4973C19.907 22.4973 23.3065 17.8782 22.3818 14.0263L20.5463 6.79061Z" fill="#536685" />
  </Icon>
)

FireHydrantIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};