import React from 'react';

import { Global, css } from '@emotion/react';

import FTPolarRegular from './FTPolar-Regular.woff2';
import FTPolarSemibold from './FTPolar-Semibold.woff2';
import FTPolarMonoRegular from './FTPolarMono-Regular.woff2';

const Fonts = () => (
  <Global
    styles={css`
      /* latin */
      @font-face {
        font-family: 'FT Polar';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(${FTPolarRegular}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'FT Polar';
        font-style: normal;
        font-weight: 1000;
        font-display: swap;
        src: url(${FTPolarSemibold}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'FT Polar Mono';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${FTPolarMonoRegular}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      `}
  />
);

export default Fonts;
