import React from 'react';

import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { colorOptions } from '../../theme/colors.theme';

export const BellIcon = (props) => (
  <Icon boxSize="13px" fill="none" viewBox="0 0 13 13" {...props}>
    <path
      d="M4.33341 10.5873C4.69015 11.3899 5.62171 11.9168 6.50008 11.9168C7.37845 11.9168 8.31001 11.3899 8.66675 10.5873M9.75008 6.06154V4.3335C9.75008 2.53857 8.29501 1.0835 6.50008 1.0835V1.0835C4.70516 1.0835 3.25008 2.53857 3.25008 4.3335V6.06154C3.25008 6.51313 3.05841 6.9435 2.72275 7.24559L2.12263 7.78571C1.40627 8.43043 1.63482 9.60598 2.54055 9.93533L2.65484 9.97689C5.13877 10.8801 7.86139 10.8801 10.3453 9.97689L10.4596 9.93533C11.3653 9.60598 11.5939 8.43043 10.8775 7.78571L10.2774 7.24559C9.94175 6.9435 9.75008 6.51313 9.75008 6.06154Z"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth=".8"
      strokeLinecap="round"
    />
  </Icon>
);

BellIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
