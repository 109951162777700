import _, { get } from 'lodash';

import firehydrantAPI from './firehydrantAPI';

export const formatLabelsToString = (labels) => {
  // Labels must have a key
  if (!labels || labels.length === 0 || !labels[0].key) {
    return '';
  }

  const formattedLabels = labels.reduce((accum, label) => {
    if (Object.keys(label).length === 0) return accum;

    // Label keys can be strings or objects. Get the value out of
    // the label key
    const labelKey = label.key?.value ?? label.key;

    if (!label['label-type'] || label['label-type'].value === 'key=val') {
      return `${labelKey}=${label.value},${accum}`;
    }
    return `${labelKey},${accum}`;
  }, '');
  return formattedLabels.replace(/,+$/, '');
};

export const formatLabelObject = (labelString) => {
  if (!labelString) return '';
  const labelArray = labelString.split(',');
  return labelArray.map((label) => {
    if (label.indexOf('=') > 0) {
      return {
        'label-type': {
          label: 'Has key and value',
          value: 'key=val',
        },
        key: label.substr(0, label.indexOf('=')),
        value: label.substr(label.indexOf('=') + 1, label.length - 1),
      };
    }
    return {
      'label-type': {
        label: 'Has Key',
        value: 'key',
      },
      key: label,
    };
  });
};

export const formatLabelInitialValues = (labelHash) => {
  if (!labelHash || Object.keys(labelHash).length === 0) return [];
  return Object.keys(labelHash).map((key) => ({ key, value: labelHash[key] }));
};

export const findInitialSelectValue = (options, value) =>
  options.find((option) => option.value === value);

export const findById = (options, value) =>
  options.find((option) => option.id === value);

export const formatSeveritySelectSubmission = (value) => {
  if (!value) return '';
  if (Array.isArray(value)) {
    return formatSeveritiesToCommaSeparatedString(value);
  }
  if (!value.value) return { severity_not_set: true };
  return { severities: value.value };
};

// Severities need to have a unique response to handle the null case
export const formatSeveritiesToCommaSeparatedString = (values) => {
  const severities = {};
  values?.forEach((value) => {
    if (!value.value) {
      severities.severity_not_set = true;
    } else if (!severities.severities) {
      severities.severities = value.value;
    } else {
      severities.severities += `,${value.value}`;
    }
  });
  return severities;
};

// Priorities need to have a unique response to handle the null case
export const formatPrioritiesToCommaSeparatedString = (values) => {
  const priorities = values
    ?.map((value) => {
      return value.value;
    })
    .join(',');
  return priorities;
};

export const formatLabelsToHash = (labels) => {
  if (!labels) return {};
  const labelHash = {};
  labels.forEach((label) => (labelHash[label.key] = label.value));
  return labelHash;
};

export const formatReactSelectToCommaSeparatedString = (values) => {
  if (!values || values.length === 0) {
    return '';
  }

  // We want to check if this is just a single object instead of an array
  if (values.value) {
    return values.value;
  }

  return values
    .filter((item) => item && item.value && item.value !== '')
    .map((item) => item.value)
    .join(',');
};

export const removeEmptyStrings = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] !== '' && obj[prop] !== []) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const formatInfrastructureForSelect = (arr) =>
  arr.map((item) => {
    if (!item) {
      return {
        label: "Couldn't Load",
        value: '',
      };
    }
    return {
      label: item.name,
      value: item.id,
    };
  });

export const formatTagsForSelect = (arr) =>
  arr.map((item) => {
    if (!item) {
      return {
        label: "Couldn't Load",
        value: '',
      };
    }
    return {
      label: item,
      value: item,
    };
  });

export const formatImpactForSelect = (arr) =>
  arr.map((item) => {
    if (!item) {
      return {
        label: "Couldn't Load",
        value: '',
      };
    }
    return {
      label: item.infrastructure.name,
      value: item.infrastructure.id,
    };
  });

export const formatLabelsForForm = (labels) => {
  return Object.keys(labels).length
    ? Object.keys(labels).map((label) => ({
      key: { label: label, value: label },
      value: labels[label],
    }))
    : [{ key: { label: '', value: '' }, value: '' }];
};

export const formatTimeRange = (values) => {
  if (!values['time-range']) {
    return {
      startsAt: '',
      endsAt: '',
    };
  }
  const time1 = values.time1 || '00:00';
  const time2 = values.time2 || '00:00';
  const range = {};

  if (values['time-range'].value === 'between') {
    range.startsAt = `${values.date1}T${time1}`;
    range.endsAt = `${values.date2}T${time2}`;
  } else if (values['time-range'].value === 'after') {
    range.startsAt = `${values.date1}T${time1}`;
  } else if (values['time-range'].value === 'before') {
    range.endsAt = `${values.date1}T${time1}`;
  } else if (values['time-range'].value !== 'none') {
    range.startsAt = values['time-range'].value;
  }
  return range;
};

export const formatAPITimeResponse = (startsAt, endsAt) => {
  const timeAgo = rangeOptions.find((option) => option.value === startsAt);
  if (timeAgo) return { 'time-range': timeAgo };
  const newValues = {};

  if (!startsAt && !endsAt) {
    newValues['time-range'] = {
      value: 'none',
      label: 'None',
    };
  } else if (endsAt && startsAt) {
    newValues.date1 = startsAt.slice(0, 10);
    newValues.time1 = startsAt.slice(11, 19);
    newValues['time-range'] = {
      value: 'between',
      label: 'Between',
    };
    newValues.date2 = endsAt.slice(0, 10);
    newValues.time2 = endsAt.slice(11, 19);
  } else if (startsAt) {
    newValues.date1 = startsAt.slice(0, 10);
    newValues.time1 = startsAt.slice(11, 19);
    newValues.advanced = true;
    newValues['time-range'] = {
      value: 'after',
      label: 'After',
    };
  } else {
    newValues.date1 = endsAt.slice(0, 10);
    newValues.time1 = endsAt.slice(11, 19);
    newValues.advanced = true;
    newValues['time-range'] = {
      value: 'before',
      label: 'Before',
    };
  }

  return newValues;
};

export const rangeOptions = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Between',
    value: 'between',
  },
  {
    label: 'After',
    value: 'after',
  },
  {
    label: 'Before',
    value: 'before',
  },
  {
    label: '1 hour ago',
    value: '1_h_ago',
  },
  {
    label: '3 hours ago',
    value: '3_h_ago',
  },
  {
    label: '6 hours ago',
    value: '6_h_ago',
  },
  {
    label: '1 day ago',
    value: '1_day_ago',
  },
];

export const labelFormat = (value, labelType) => {
  // Now that the asyncUrl starts with a slash to get the fallback working again we need
  // to strip the leading slash
  let formattedLabelType = labelType;
  if (formattedLabelType[0] === '/') {
    formattedLabelType = formattedLabelType.substr(
      1,
      formattedLabelType.length - 1,
    );
  }

  if (formattedLabelType === 'users') {
    return {
      label: `${value.name} (${value.email})`,
      value: value.id,
      original: value,
    };
  }
  if (formattedLabelType === 'saved_searches/change_events') {
    const timeOptions = formatAPITimeResponse(
      value.filter_values.starts_at,
      value.filter_values.ends_at,
    );
    const filterValues = Object.assign({}, value.filter_values, timeOptions);
    const formattedValue = Object.assign({}, filterValues, {
      id: value.id,
      savedSearchName: value.name,
    });
    return {
      label: value.name,
      value: formattedValue,
      original: value,
    };
  }
  if (formattedLabelType === 'severities') {
    return {
      label: value.slug,
      value: value.slug,
      original: value,
    };
  }
  if (formattedLabelType === 'priorities') {
    return {
      label: value.slug,
      value: value.slug,
      original: value,
    };
  }
  if (formattedLabelType === 'action') {
    return {
      label: `${value.integration.name} - ${value.name}`,
      value: value.id,
      original: value,
    };
  }
  if (formattedLabelType === 'infrastructures') {
    return {
      label: `${value.type}: ${value.infrastructure.name}`,
      value: value.infrastructure.id,
      original: value,
    };
  }
  if (formattedLabelType === 'tags') {
    return {
      label: value.name,
      value: value.name,
    };
  }
  if (formattedLabelType === 'externalLink') {
    return {
      label: value.name,
      value: value.remote_id,
    };
  }
  if (formattedLabelType === 'fhAttributesLabelKeys') {
    return {
      label: value.label,
      value: value.value,
    };
  }

  if (formattedLabelType === 'incident') {
    return {
      label: value.number ? `${value.number} - ${value.name}` : value.name,
      value: value.id,
    };
  }

  if (formattedLabelType === 'statusPage') {
    return {
      label: value.display_name,
      value: {
        connection_id: value.id,
        integration_slug: value.integration_slug,
      },
    };
  }
  if (formattedLabelType === 'webhook') {
    return {
      label: value.url,
      value: value.id,
    };
  }
  if (formattedLabelType === 'msTeamsChannel') {
    return {
      label: value.channel_name + (value.team_name ? ` (${value.team_name})` : ''),
      value: { channel_id: value.channel_id, ms_team_id: value.ms_team_id },
    };
  }

  return {
    label: value.name,
    value: value.id || value.slug,
    original: value,
  };
};

export const loadOptions = async (
  query,
  path,
  customOptionBuilderFunction,
  queryFieldName = 'query',
  additionalParams,
  callback,
) => {
  const optionBuilderFunction =
    customOptionBuilderFunction || ((value) => labelFormat(value, path));

  const params = {
    [queryFieldName]: query,
    ...additionalParams,
    per_page: 1000,
  };
  const response = await firehydrantAPI.get(path, { params });
  callback(response.data.data.map(optionBuilderFunction));
};

export const loadPaginationOptions = async (
  query,
  path,
  customOptionBuilderFunction,
  queryFieldName = 'query',
  additionalParams,
  page,
) => {
  const optionBuilderFunction =
    customOptionBuilderFunction || ((value) => labelFormat(value, path));

  const params = {
    [queryFieldName]: query,
    ...additionalParams,
    per_page: 50,
    page,
  };
  const {
    data: { data, pagination },
  } = await firehydrantAPI.get(path, { params });

  return {
    options: data.map(optionBuilderFunction),
    hasMore: pagination.next,
    additional: {
      page: page + 1,
    },
  };
};

export const formatUserOptionsForSelect = (users) =>
  users.map((user) => ({
    value: user.id,
    label: `${user.name} (${user.email})`,
  }));

export const formatActionsForSelect = (actions) => {
  const groupedActions = {};
  actions.forEach((action) => {
    if (groupedActions[action.integration.name] === undefined) {
      groupedActions[action.integration.name] = [];
    }
    groupedActions[action.integration.name].push({
      label: action.name,
      value: action.id,
      original: action,
    });
  });

  const forSelect = [];
  Object.keys(groupedActions).forEach((action) => {
    const options = groupedActions[action];
    const configUrl = get(options, '0.original.integration.url', null);

    // Check to see if every option has `enabled` set to true.
    const checkStatus = (option) => option.original.integration.enabled;
    const enabled = options.every(checkStatus);

    forSelect.push({
      configUrl,
      enabled,
      label: action,
      options,
    });
  });

  return forSelect;
};

export const convertImpactArray = (type, impactList) => {
  if (!impactList) return [];
  const result = impactList
    .filter((i) => !!(i.impact?.id || i.impact?.value))
    .map((i) => ({
      type,
      id: i.impact?.id || i.impact.value,
      condition_id: i.condition?.id || i.condition.value,
    }));
  return result;
};

export const convertAPIToImpactArray = (type, impactList) => {
  if (!impactList) return [];

  const filteredImpactListByType = impactList.filter(
    (item) => item.type === type,
  );
  return filteredImpactListByType.map((i) => ({
    condition: {
      id: i.condition?.id || '',
      label: i.condition?.name || '',
    },
    impact: {
      id: i.impact.id,
      label: i.impact.name,
    },
  }));
};

export const capitalize = (str) =>
  str[0].toUpperCase() + str.substr(1, str.length - 1);

export const optionsFromArrayOfObjects = (arrayOfObjects, labelKey, valueKey) =>
  arrayOfObjects.map((object) => ({
    label: object[labelKey],
    value: object[valueKey],
  }));

/*
  values = [
    {key: 'one', field: 1, field2: 1},
    {key: 'two', field: 2, field2: 2},
    {key: 'one', field: 11, field2: 11}
  ]
  groupMap(values, 'key', ({ field }) => ({ field }))

  // => {
  //   one: [{field: 1}, {field: 11}],
  //   two: [{field: 2}]
  // }
*/
export const groupMap = (arr, keyProp, mapFn) => {
  const outputHash = {};

  arr.forEach((obj) => {
    const key = obj[keyProp];

    outputHash[key] = outputHash[key] || [];
    outputHash[key].push(mapFn(obj));
  });

  return outputHash;
};

export const updateSharedSelectOptions = ({
  masterListAsOptions,
  updateValue,
  currentProps,
  previousProps,
  masterListKey,
  selectedOptionsFormKey,
}) => {
  const currentForm = currentProps.form;
  const prevForm = previousProps.form;

  /*
    This code is awful.

    We're attempting to filter all of the masterListAsOptions down
    to the ones that have not already been selected between all of the
    statusConditions multiselect elements.

    There is currently a bug on the NEW page if the first and second
    form interactions you make are selecting status conditions. Because
    this is such a small edge case, we decided to leave it.

    If we can find a way to rewrite this code in a sane way, please do it
    and tell Justin all about how you did it.
  */
  const optionsExist = currentProps[masterListKey].length !== 0;
  if (optionsExist) {
    const formValuesJustChanged =
      currentForm.values &&
      prevForm.values &&
      JSON.stringify(currentForm.values[selectedOptionsFormKey]) !==
      JSON.stringify(prevForm.values[selectedOptionsFormKey]);

    const optionsJustSet = previousProps[masterListKey].length === 0;
    if (optionsJustSet) {
      updateValue(masterListAsOptions);
    } else if (formValuesJustChanged) {
      const allSelectedValues = _.flatMap(
        currentForm.values[selectedOptionsFormKey],
      );
      updateValue(
        _.differenceWith(masterListAsOptions, allSelectedValues, _.isEqual),
      );
    }
  }
};

export const initializeSharedSelectOptions = ({
  masterListAsOptions,
  updateValue,
  initiallySelectedValues,
}) => {
  updateValue(
    _.differenceWith(masterListAsOptions, initiallySelectedValues, _.isEqual),
  );
};

export const formatIncidentFilterForCSV = (values) => {
  const params = Object.assign({});

  Object.entries(values).forEach(([key, v]) => {
    // NOTE(bobbytables): I can hear you now, "wtf is this?" Well my friend...
    //
    // This function modifies the form values for the incident filter into an object that can be posted to the API to kick off an incident,
    // the values need to match the format of the IncidentReader params. The form contains a bunch of nonsense that isn't needed and actually the
    // filter values are technically wrong (empty arrays will technically filter to nothing in our readers, as one example).
    // So these are the three cases that this filters out, giving us a clean JSON object that exports incidents.
    if (!v) {
      return;
    }
    if (Array.isArray(v) && v.length === 0) {
      return;
    }
    if (key === 'id' || key === 'savedSearchName') {
      return;
    }

    if (Array.isArray(v)) {
      if (key === 'labels') {
        params.labels = values.labels.map((v) => {
          if (v.value) {
            return `${v.key.value}=${v.value}`;
          }
          return v.key.value;
        });
      } else {
        params[key] = v.map((v) => v.value);
      }

      params[key] = params[key].join(',');
    } else if (v.label) {
      params[key] = v.value;
    } else {
      params[key] = v;
    }
  });
  return params;
};
