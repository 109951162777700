import axios from 'axios';

import { customParamsSerializer } from './customAxiosParamsSerializer';

const internalAPI = axios.create({
  baseURL: '/',
  withCredentials: true,
  paramsSerializer: {
    indexes: null,
    serialize: (params) => {
      return customParamsSerializer(params);
    },
  },
});

internalAPI.interceptors.request.use(
  (config) => {
    const tokenDom = document.querySelector('meta[name=csrf-token]');
    config.headers['X-CSRF-Token'] = tokenDom.content;
    return config;
  },
  (error) => Promise.reject(error),
);

export default internalAPI;
