import React from 'react';

import { Icon, forwardRef } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { colorOptions } from '../../theme/colors.theme';

export const TimeIcon = forwardRef((props, ref) => (
  <Icon boxSize="16px" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
    <path
      d="M8 4V8H11M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
));

TimeIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
