export const Link = {
  variants: {
    primary: {
      color: 'gray.700',
      fontWeight: 1,
      textDecoration: 'underline',
    },
    bold: {
      color: 'gray.700',
      fontWeight: '500',
      textDecoration: 'underline',
    },
  },
  defaultProps: {
    size: 5,
    fontWeight: 1,
    textDecoration: 'underline',
  },
  baseStyle: {
    color: 'gray.700',
    fontWeight: 1,
    textDecoration: 'underline',
    _hover: {
      color: 'primary.700',
    },
  },
};
