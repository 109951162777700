import { throttle } from '../utils';
import { useLayoutEffect } from 'react';

export function useScrollEffect(ref, effect) {
  const fn = throttle(effect);

  useLayoutEffect(() => {
    const el = ref === window ? ref : ref.current;
    if (!el) return;

    el.addEventListener('scroll', fn);
    return () => {
      el.removeEventListener('scroll', fn);
      fn?.cancel?.();
    };
  }, [ref]);
}
