import React from 'react';

import { Icon, forwardRef } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { colorOptions } from '../../theme/colors.theme';

export const InfoIcon = forwardRef((props, ref) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props} ref={ref}>
    <path
      d="M11.0141 8.99531H12.9859V6.97653H11.0141V8.99531ZM12 20.0282C10.5603 20.0282 9.2144 19.6682 7.96244 18.9484C6.74178 18.2285 5.77152 17.2582 5.05164 16.0376C4.33177 14.7856 3.97183 13.4397 3.97183 12C3.97183 10.5603 4.33177 9.23005 5.05164 8.00939C5.77152 6.75743 6.74178 5.77152 7.96244 5.05164C9.2144 4.33177 10.5603 3.97183 12 3.97183C13.4397 3.97183 14.77 4.33177 15.9906 5.05164C17.2426 5.77152 18.2285 6.75743 18.9484 8.00939C19.6682 9.23005 20.0282 10.5603 20.0282 12C20.0282 13.4397 19.6682 14.7856 18.9484 16.0376C18.2285 17.2582 17.2426 18.2285 15.9906 18.9484C14.77 19.6682 13.4397 20.0282 12 20.0282ZM12 2C10.6854 2 9.40219 2.25039 8.15024 2.75117C6.92958 3.25196 5.84977 3.97183 4.9108 4.9108C3.97183 5.84977 3.25196 6.94523 2.75117 8.19718C2.25039 9.41784 2 10.6854 2 12C2 13.3146 2.25039 14.5978 2.75117 15.8498C3.25196 17.0704 3.97183 18.1502 4.9108 19.0892C5.84977 20.0282 6.92958 20.748 8.15024 21.2488C9.40219 21.7496 10.6854 22 12 22C13.3146 22 14.5822 21.7496 15.8028 21.2488C17.0548 20.748 18.1502 20.0282 19.0892 19.0892C20.0282 18.1502 20.748 17.0704 21.2488 15.8498C21.7496 14.5978 22 13.3146 22 12C22 10.6854 21.7496 9.41784 21.2488 8.19718C20.748 6.94523 20.0282 5.84977 19.0892 4.9108C18.1502 3.97183 17.0548 3.25196 15.8028 2.75117C14.5822 2.25039 13.3146 2 12 2ZM11.0141 17.0235H12.9859V11.0141H11.0141V17.0235Z"
      fill="currentColor"
    />
  </Icon>
));

InfoIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
