/**
 * Axios 1.x upgrades has a breaking change in how query params are serialized.
 * As a workaround, Axios 1.x does have an optional config that allows you to
 * customize serializing `params`
 *
 * {
 *   paramsSerializer: {
 *     serialize: () => {}
 *   }
 * }
 *
 * See: https://github.com/axios/axios/pull/4734 which changed the entire
 * behavior of the params serializer
 *
 * To ensure backwards behavior in our app, this file provides a custom params
 *  serializer that uses (ie. copies) the 0.x version of `buildURL.js`
 */

/**
 * It replaces all instances of the characters `:`, `$`, `,`, `+`, `[`, and `]` with their
 * URI encoded counterparts
 *
 * @param {string} val The value to be encoded.
 *
 * @returns {string} The encoded value.
 */
function encode(val) {
  return encodeURIComponent(val)
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+')
    .replace(/%5B/gi, '[')
    .replace(/%5D/gi, ']');
}

function forEach(obj, fn) {
  // Don't bother if no value provided
  if (obj === null || typeof obj === 'undefined') {
    return;
  }

  // Check if obj is array-like
  var isArrayLike =
    Array.isArray(obj) || toString.call(obj) === '[object Arguments]';

  // Force an array if not already something iterable
  if (typeof obj !== 'object' && !isArrayLike) {
    obj = [obj];
  }

  // Iterate over array values
  if (isArrayLike) {
    for (var i = 0, l = obj.length; i < l; i++) {
      fn.call(null, obj[i], i, obj);
    }
  }
  // Iterate over object keys
  else {
    for (var key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        fn.call(null, obj[key], key, obj);
      }
    }
  }
}

function isDate(val) {
  return toString.call(val) === '[object Date]';
}

export function customParamsSerializer(params) {
  let parts = [];
  let serializedParams;

  forEach(params, function (val, key) {
    if (val === null || typeof val === 'undefined') {
      return;
    }

    if (Array.isArray(val)) {
      key = `${key}[]`;
    }

    if (!Array.isArray(val)) {
      val = [val];
    }

    forEach(val, function (v) {
      if (isDate(v)) {
        v = v.toISOString();
      } else if (v !== null && typeof v === 'object') {
        v = JSON.stringify(v);
      }
      parts.push(`${encode(key)}=${encode(v)}`);
    });
  });

  serializedParams = parts.join('&');

  return serializedParams;
}
