import React from 'react';

import { forwardRef } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';

const OrgRouteLink = forwardRef((props, ref) => {
  const { orgId } = useParams();
  let to = props.to;

  if (props.to && props.to.charAt(0) === '/' && orgId) {
    to = `/org/${orgId}${props.to}`;
  }
  return <Link {...props} to={to} ref={ref} />;
});

export default OrgRouteLink;
