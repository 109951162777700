import React from 'react';

import PropTypes from 'prop-types';

import { Tag } from '../tag';
import { ToggleButton } from '../toggle-button';

export function FilterToggleButton({ children, count, ...props }) {
  return (
    <ToggleButton gap="2" {...props}>
      {children}
      {null !== count && typeof count !== 'undefined' && (
        <Tag size="sm">{count}</Tag>
      )}
    </ToggleButton>
  );
}
FilterToggleButton.propTypes = {
  children: PropTypes.node,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
