import { space } from '../../theme/space.theme';

export const Radio = {
  baseStyle: {},
  sizes: {
    md: {
      control: {
        w: space[4],
        h: space[4],
      },
      label: {
        fontSize: 5,
        fontWeight: 5,
        lineHeight: 5,
      },
    },
  },
  variants: {
    primary: {
      control: {
        cursor: 'pointer',
        borderRadius: 'full',
        borderColor: 'grey.500',
        bg: 'grey.0',
        border: '1px',
        my: '10px',
        alignSelf: 'start',
        _invalid: {
          borderColor: 'red.700',
          bg: 'grey.0',
        },
        _groupHover: {
          _groupHover: {
            borderColor: 'primary.500',
            bg: 'primary.100',
            _invalid: {
              bg: 'red.100',
              borderColor: 'red.500',
            },
          },
        },
        _groupActive: {
          _groupActive: {
            borderColor: 'primary.900',
            bg: 'primary.300',
            _invalid: {
              borderColor: 'red.900',
              bg: 'red.300',
              color: 'red.700',
            },
          },
        },
        _focus: {
          borderColor: 'primary.700',
          bg: 'primary.100',

          _invalid: {
            borderColor: 'red.700',
            bg: 'red.100',
          },
        },
        _checked: {
          borderColor: 'primary.600',
          color: 'grey.0',
          bg: 'primary.600',
          _invalid: {
            borderColor: 'red.700',
            bg: 'grey.0',
            color: 'red.700',
          },
          _hover: {
            borderColor: 'primary.700',
            color: 'grey.0',
            bg: 'primary.700',
          },
          _groupHover: {
            _groupHover: {
              borderColor: 'primary.500',
              bg: 'primary.100',
              color: 'primary.500',
              _invalid: {
                borderColor: 'red.500',
                bg: 'red.100',
                color: 'red.700',
              },
            },
          },
          _groupActive: {
            _groupActive: {
              borderColor: 'primary.900',
              color: 'primary.300',
              bg: 'primary.900',

              _invalid: {
                borderColor: 'red.900',
                bg: 'red.300',
                color: 'red.700',
              },
            },
          },
          _focus: {
            borderColor: 'primary.700',
            color: 'primary.100',
            bg: 'primary.700',
            _groupActive: {},
            _invalid: {
              borderColor: 'red.700',
              bg: 'red.100',
              color: 'red.700',
            },
          },
          _readOnly: {
            borderColor: 'grey.500',
            bg: 'grey.100',
            color: 'grey.500',
            cursor: 'not-allowed',
            _focus: {
              borderColor: 'grey.500',
              bg: 'grey.100',
              color: 'grey.500',
            },
            _groupHover: {
              _groupHover: {
                borderColor: 'grey.500',
                bg: 'grey.100',
                color: 'grey.500',
              },
            },
            _groupActive: {
              _groupActive: {
                borderColor: 'grey.500',
                bg: 'grey.100',
                color: 'grey.500',
              },
            },
          },
          _before: {
            content: `""`,
            display: 'inline-block',
            pos: 'relative',
            w: '12px',
            h: '12px',
            borderRadius: '50%',
            bg: 'currentColor',
          },
        },
        _readOnly: {
          borderColor: 'grey.500',
          bg: 'grey.100',
          color: 'grey.0',
          cursor: 'not-allowed',
          _groupActive: {
            _groupActive: {},
          },
          _groupHover: {
            _groupHover: {
              borderColor: 'grey.500',
              bg: 'grey.100',
            },
          },
          _focus: {},
        },
      },
      label: {
        cursor: 'pointer',
        color: 'grey.900',
        py: space['mg2'],
        pl: 2,
        ml: 0,
        _invalid: {
          color: 'red.700',
        },
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
};
