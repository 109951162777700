import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const OrganizationOutlineIcon = (props) => (
  <Icon boxSize="24px" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 4c-.969 0-1.797.394-2.484 1.181-.688.752-1.032 1.683-1.032 2.792 0 1.11.344 2.058 1.032 2.846C10.203 11.606 11.03 12 12 12c.969 0 1.797-.394 2.484-1.181.688-.788 1.032-1.736 1.032-2.846s-.344-2.04-1.032-2.792C13.797 4.394 12.97 4 12 4zm0 2.255c.406 0 .75.179 1.031.537.313.322.469.734.469 1.235 0 .465-.156.859-.469 1.181-.281.322-.625.483-1.031.483-.406 0-.766-.16-1.078-.483-.281-.322-.422-.716-.422-1.181 0-.501.14-.913.422-1.235.312-.358.672-.537 1.078-.537zM5.484 7.436c-.687 0-1.28.287-1.78.86C3.233 8.831 3 9.493 3 10.281c0 .537.11 1.02.328 1.45.25.43.563.77.938 1.02.406.25.812.375 1.218.375.438 0 .844-.107 1.22-.322.374-.25.687-.59.937-1.02-.75-1.11-1.125-2.38-1.125-3.812v-.322a2.446 2.446 0 00-1.032-.215zm13.032 0c-.375 0-.72.072-1.032.215v.322c0 1.432-.375 2.703-1.125 3.812.125.18.266.376.422.59.188.18.36.323.516.43.375.215.765.322 1.172.322.437 0 .843-.125 1.218-.375.407-.25.72-.59.938-1.02.25-.43.375-.913.375-1.45 0-.788-.25-1.45-.75-1.987-.469-.572-1.047-.859-1.734-.859zM12 14.31c-.875 0-1.828.143-2.86.43-1.156.321-2.109.769-2.859 1.341-.843.645-1.265 1.379-1.265 2.202V20h13.968v-1.718c0-.823-.422-1.557-1.265-2.201-.75-.573-1.703-1.02-2.86-1.343-1.03-.286-1.984-.43-2.859-.43zm-7.313.59c-1.156.18-2.171.537-3.046 1.074C.547 16.617 0 17.387 0 18.282V20h3v-2.201c0-1.074.563-2.04 1.688-2.9zm14.625 0c1.125.86 1.688 1.826 1.688 2.9V20h3v-1.718c0-.895-.547-1.665-1.64-2.309-.876-.537-1.891-.895-3.047-1.074zM12 16.564c.719 0 1.484.125 2.297.376.75.214 1.39.483 1.922.805H7.78c.532-.322 1.172-.59 1.922-.805.813-.251 1.578-.376 2.297-.376z"
      fill="currentColor"
    />
  </Icon>
);

OrganizationOutlineIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
