import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const TicketIcon = (props) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props}>
    <path
      d="M20.028 12c0 .561.188 1.045.564 1.45.406.375.876.562 1.408.562v3.976c0 .562-.203 1.045-.61 1.45-.376.375-.83.562-1.362.562H3.972a2.018 2.018 0 01-1.409-.561A2.061 2.061 0 012 17.989v-3.977c.532 0 .986-.187 1.361-.562.407-.405.61-.889.61-1.45 0-.561-.203-1.03-.61-1.403-.375-.406-.829-.609-1.361-.609V6.012c0-.562.188-1.03.563-1.404C2.97 4.203 3.44 4 3.972 4h16.056c.532 0 .986.203 1.362.608.407.374.61.842.61 1.404v3.976c-.532 0-1.002.203-1.408.609-.376.374-.564.842-.564 1.403zm-3.521 4.257c0-.686-.563-1.247-1.69-1.684A7.558 7.558 0 0012 14.012c-.939 0-1.878.187-2.817.561-1.127.437-1.69.998-1.69 1.684v.749h9.014v-.749zM12 12.234c.626 0 1.158-.218 1.596-.655.438-.437.658-.967.658-1.59 0-.624-.22-1.155-.658-1.591A2.177 2.177 0 0012 7.743c-.626 0-1.158.218-1.596.655a2.162 2.162 0 00-.658 1.59c0 .624.22 1.154.658 1.59.438.438.97.656 1.596.656z"
      fill="currentColor"
    />
  </Icon>
);

TicketIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
