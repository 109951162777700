import React from 'react';

import { Badge, Skeleton } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export function IncidentSeverityBadge({
  value,
  color,
  isLoading = false,
  ...props
}) {
  if (isLoading === false && !value) {
    return null;
  }

  return (
    <Skeleton
      isLoaded={value || isLoading === false}
      borderRadius="full"
      minWidth="6ch"
      maxWidth="20ch"
      display="inline-block"
      {...props}
    >
      <Badge
        colorScheme={color || 'grey'}
        textAlign="center"
        variant="severity"
        width="100%"
        textOverflow="ellipsis"
        overflow="hidden"
        fontWeight={500}
        lineHeight="1.2"
        mb={1}
      >
        {value}
      </Badge>
    </Skeleton>
  );
}
IncidentSeverityBadge.propTypes = {
  isLoading: PropTypes.bool,
  value: PropTypes.string,
};
