import React from 'react';

import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { colorOptions } from '../../theme/colors.theme';

export const FireIcon = (props) => (
  <Icon boxSize="13px" fill="none" viewBox="0 0 13 13" {...props}>
    <path
      d="M6.49976 11.9168C5.73995 11.9168 4.99769 11.6892 4.36921 11.2637C3.74073 10.8381 3.25501 10.2342 2.97505 9.53024C2.69508 8.82627 2.63378 8.05471 2.79908 7.3156C2.96438 6.57649 3.34866 5.90392 3.9021 5.38509C4.58059 4.74869 6.24697 3.60288 5.99418 1.0835C9.02764 3.099 10.5444 5.1145 7.51091 8.13776C8.01649 8.13776 8.77485 8.13776 10.0388 6.89319C10.1753 7.28268 10.2916 7.7014 10.2916 8.13776C10.2916 9.14003 9.89209 10.1013 9.18098 10.81C8.46988 11.5187 7.50541 11.9168 6.49976 11.9168Z"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth=".8"
    />
  </Icon>
);

FireIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
