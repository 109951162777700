import firehydrantAPI from 'helpers/firehydrantAPI';
import internalAPI from 'helpers/internalAPI';

export const addAPIRequestHeaderInterceptor = (header, value) => {
  const handleToken = (config) => {
    const newConfig = Object.assign({}, config);

    const token = value;
    if (token) {
      newConfig.headers[header] = value;
    }

    return newConfig;
  };

  const handleError = (error) => Promise.reject(error);

  if (header && value) {
    firehydrantAPI.interceptors.request.use(handleToken, handleError);
    internalAPI.interceptors.request.use(handleToken, handleError);
  }
};
