import React from 'react';

import { Flex, Square, Text, useMultiStyleConfig } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { IconButton } from '../icon-button';
import { Icon } from '../icons';

export function Alert({
  children,
  dismissable,
  onDismiss,
  variant = 'info',
  ...rest
}) {
  const styles = useMultiStyleConfig('Alert', { variant });

  const iconColorScheme = {
    alert: 'red.500',
    info: 'oceanBlue.700',
    success: 'green.500',
    warning: 'yellow.500',
  };

  return (
    <Flex __css={styles.container} borderRadius={4} variant={variant} {...rest}>
      <Flex justifyContent={dismissable ? 'space-between' : 'auto'}>
        <Flex align="flex-start">
          <Square
            bgColor={iconColorScheme[variant]}
            p="1"
            mr="3"
            borderRadius="4"
          >
            <Icon name={variant || 'info'} color="gray.0" boxSize="5" />
          </Square>
          <Text color="gray.900" size="5" mb="0">
            {children}
          </Text>
        </Flex>
        {dismissable && (
          <IconButton
            aria-label="close"
            name="close"
            onClick={onDismiss}
            variant="tertiary"
            size="xs"
          />
        )}
      </Flex>
    </Flex>
  );
}

Alert.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['alert', 'info', 'success', 'warning']),
  dismissable: PropTypes.bool,
  onDismiss: PropTypes.func,
};
