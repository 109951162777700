/* this is a fallback throttle method in case requestAnimationFrame is not defined */
function fallbackThrottle(func) {
  let wait = false;
  let storedArgs = null;
  let timeouts = [];

  function checkStoredArgs() {
    if (storedArgs == null) {
      wait = false;
    } else {
      func(...storedArgs);
      storedArgs = null;
      timeouts.push(setTimeout(checkStoredArgs, 166));
    }
  }

  const throttled = (...args) => {
    if (wait) {
      storedArgs = args;
      return;
    }

    func(...args);
    wait = true;
    timeouts.push(setTimeout(checkStoredArgs, 166));
  };

  throttled.cancel = () => {
    while (timeouts.length > 0) {
      clearTimeout(timeouts.pop());
    }
  };

  return throttled;
}

/* throttling with requestAnimationFrame makes scrolling (or events related to animation) smoother */
export function throttle(func) {
  if (typeof func !== 'function') {
    return;
  }

  if (!requestAnimationFrame) return fallbackThrottle(func);

  let requestId = null;
  let storedArgs;

  const later = () => () => {
    requestId = null;
    func(...storedArgs);
  };

  const throttled = function (...args) {
    storedArgs = args;
    if (requestId === null) {
      requestId = requestAnimationFrame(later());
    }
  };

  throttled.cancel = () => {
    cancelAnimationFrame(requestId);
    requestId = null;
  };

  return throttled;
}
