import React, { useContext, useEffect, useState } from 'react';

import { addAPIRequestHeaderInterceptor } from 'helpers/apiRequestHelpers';
import PropTypes from 'prop-types';
import { useCurrentAuthContextGql } from 'queries/users';
import { v4 as uuidV4 } from 'uuid';

import { orgIdFromLocation } from 'components/routes/multiOrgRoutesHelpers';

import UserContext from './UserContext';

export const useUserContext = () => {
  return useContext(UserContext);
};

export const useOrgMetadata = () => {
  const { currentOrganization } = useContext(UserContext);

  return currentOrganization?.node?.metadata;
};

export const UserContextProvider = ({ children }) => {
  const excludedPaths = [
    '/',
    '/sessions/new',
    '/sessions/sso/new',
    '/registrations',
  ];

  const isExcludedPath = excludedPaths.some((pathname) => {
    return window.location.pathname === pathname; // Changed to strict equality for precise matching
  });

  // We need this for avatar caching
  const [UID, setUID] = useState(uuidV4());
  const [hasBearerToken, setHasBearerToken] = useState(null);
  const {
    data: currentAuthContext,
    isLoading,
    refetch,
  } = useCurrentAuthContextGql({
    refetchOnMount: false,
    // Disable the fetch for the login and registration pages which don't
    // require currentUser context. The server handles redirects to the app
    // from the login page when a valid user is logged in. This also prevents
    // reporting expected unauthorized errors to Bugsnag for the login page.
    enabled: !isExcludedPath,
    onSuccess: () => {
      setUID(uuidV4());
    },
  });

  const orgId = orgIdFromLocation();

  const currentUserOrganizations = currentAuthContext?.organizations?.edges;
  const account = currentAuthContext?.account;

  const findOrgById = (orgId) => {
    const currentOrg = currentUserOrganizations?.find(
      (org) => org.node.slug === orgId || org.node.id === orgId,
    );
    return currentOrg;
  };

  const currentOrganization =
    orgId && currentUserOrganizations
      ? findOrgById(orgId) // get org from URL
      : findOrgById(
          currentAuthContext?.defaultOrganization?.slug ||
            currentAuthContext?.defaultOrganization?.id,
        ); //get default org

  // Set the currentOrganization token to request headers
  useEffect(() => {
    if (currentOrganization?.token) {
      addAPIRequestHeaderInterceptor(
        'Authorization',
        `Bearer ${currentOrganization?.token}`,
      );
      // We need to restrict fetching until the bearer token has been added.
      setHasBearerToken(true);
    }
  }, [currentOrganization]);
  // CurrentUser scoped to the organization they are accessing
  const currentUser = {
    // User properties
    defaultOrganization: currentAuthContext?.defaultOrganization,
    email: currentAuthContext?.email,
    isAdminUser: currentAuthContext?.isSuperDuperUser,
    featureFlagIdentity: currentAuthContext?.featureFlagIdentity,
    isStaffSession: currentAuthContext?.['__typename'] === 'StaffSession',
    name: currentAuthContext?.name,
    // User Profile properties (organization-based)
    enabled: currentOrganization?.enabled,
    id: currentOrganization?.id,
    isSlackConnected: currentOrganization?.isLinkedToSlack,
    hasRestrictedAccess: currentOrganization?.hasRestrictedAccess,
    role: currentOrganization?.role,
    ssoEnabled: currentOrganization?.node?.hasSsoConfigured,
    tags: currentOrganization?.tags,
  };

  const isUserOwner = currentUser?.role === 'owner';

  const isUserStaffSession = currentUser?.isStaffSession;

  return (
    <UserContext.Provider
      value={{
        currentOrganization,
        currentUser,
        currentUserOrganizations,
        isLoading,
        isUserOwner,
        isUserStaffSession,
        hasBearerToken,
        UID,
        refetch,
        account,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node,
};

// Used for testing purposes only
export const UserContextProviderMock = ({ children, mockCurrentUser }) => {
  const currentUser = mockCurrentUser ?? {
    id: '12345678',
    email: 'pvp@firehydrant.com',
    name: 'Patchy Von Patches',
    role: 'member',
    enabled: true,
    defaultOrganization: {
      id: 'special-organization-id',
      name: 'Special Organization',
      slug: 'special-organization',
    },
    ssoEnabled: false,
    hasRestrictedAccess: false,
    tags: [],
    isStaffSession: false,
    organizations: {
      edges: [
        {
          id: 'firehydrant-user-id',
          role: 'owner',
          enabled: true,
          hasRestrictedAccess: true,
          isLinkedToSlack: true,
          icalUrl: 'https://firehydrant.io/ical/12345678.ics',
          tags: ['tag-1', 'tag-2'],
          token: 'special-token',
          node: {
            id: 'special-organization-id',
            name: 'Special Organization',
            slug: 'special-organization',
            hasSsoConfigured: true,
            metadata: {
              showPriority: true,
            },
          },
        },
      ],
    },
  };

  const isLoading = false;

  const isUserOwner = currentUser?.role === 'owner';

  const isUserStaffSession = currentUser?.isStaffSession;

  const currentUserOrganizations = currentUser?.organizations?.edges;

  let currentOrganization = null;
  // If we pass in a currentOrganizationId, use that to find the currentOrganization
  if (mockCurrentUser?.currentOrganizationId) {
    currentOrganization = currentUserOrganizations?.find(
      (org) => org.node.id === mockCurrentUser?.currentOrganizationId,
    );
    // Otherwise, use the defaultOrganization
  } else {
    currentOrganization = currentUserOrganizations?.find(
      (org) => org.node.id === currentUser?.defaultOrganization?.id,
    );
  }

  const refetch = jest.fn();

  return (
    <UserContext.Provider
      value={{
        currentOrganization,
        currentUser,
        currentUserOrganizations,
        isUserOwner,
        isUserStaffSession,
        isLoading,
        refetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProviderMock.propTypes = {
  children: PropTypes.node,
  mockCurrentUser: PropTypes.object,
};
