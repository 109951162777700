import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const RefreshIcon = (props) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props}>
    <path
      d="M18.9745 8.02339L14.9957 12H17.9915C17.9915 13.0916 17.7262 14.1053 17.1957 15.0409C16.6652 15.9454 15.9319 16.6628 14.9957 17.193C14.0908 17.7232 13.0922 17.9883 12 17.9883C11.0014 17.9883 10.0652 17.7544 9.19149 17.2865L7.74043 18.7368C9.05106 19.5789 10.4709 20 12 20C13.4355 20 14.7617 19.6413 15.9787 18.924C17.227 18.2066 18.2099 17.2398 18.9277 16.0234C19.6454 14.7758 20.0043 13.4347 20.0043 12H23L18.9745 8.02339ZM6.00851 12C6.00851 10.9084 6.27376 9.91033 6.80426 9.00585C7.33475 8.07018 8.05248 7.33723 8.95745 6.80702C9.89362 6.2768 10.9078 6.0117 12 6.0117C12.9986 6.0117 13.9348 6.24561 14.8085 6.71345L16.2596 5.26316C14.9489 4.42105 13.5291 4 12 4C10.5645 4 9.2227 4.35867 7.97447 5.07602C6.75745 5.79337 5.79007 6.77583 5.07234 8.02339C4.35461 9.23977 3.99574 10.5653 3.99574 12H1L5.02553 15.9766L9.00426 12H6.00851Z"
      fill="currentColor"
    />
  </Icon>
);

RefreshIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
