import React from 'react';

import PropTypes from 'prop-types';
import { AiOutlineEdit } from 'react-icons/ai';

import { colorOptions } from '../../theme/colors.theme';

export const EditIcon = (props) => <AiOutlineEdit {...props} />;

EditIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
