import React, { useLayoutEffect, useRef, useState } from 'react';

import {
  Box,
  TabList as ChakraTabList,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
} from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';

import { MenuIcon } from '../icons';
import { Scrollable } from '../scrollable';

export const ResponsiveTab = forwardRef(({ children, ...props }, ref) => {
  return (
    <Tab
      ref={ref}
      whiteSpace="nowrap"
      mb="0"
      backgroundColor="transparent"
      _focus={{}}
      _focusVisible={{}}
      {...props}
    >
      {children}
    </Tab>
  );
});

export const ResponsiveTabList = forwardRef(
  ({ children, onMenuItemClick, tabs, ...props }, ref) => {
    const tabRef = useRef([]);
    const scrollableRef = useRef(null);
    const [shouldShowMenu, setShouldShowMenu] = useState();

    useLayoutEffect(() => {
      const handleResize = () => {
        if (tabRef?.current?.offsetWidth < tabRef?.current?.scrollWidth) {
          setShouldShowMenu(true);
        } else {
          setShouldShowMenu(false);
        }
      };

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <Box
        pr={shouldShowMenu ? '50px' : null}
        position="relative"
        ref={ref}
        {...props}
      >
        <Box
          position="relative"
          borderBottom="1px solid"
          borderColor="gray.100"
        >
          <Scrollable
            bottom="-2px"
            direction="horizontal"
            position="relative"
            ref={scrollableRef}
          >
            <ChakraTabList borderBottom="none" pt="4px" ref={tabRef}>
              {children}
            </ChakraTabList>
          </Scrollable>
        </Box>
        {shouldShowMenu ? (
          <Box
            pb="1"
            bg="white"
            position="absolute"
            top="0"
            bottom="-4px"
            right="0"
            zIndex="1"
          >
            <Menu flip isLazy preventOverflow computePositionOnMount>
              <MenuButton
                ml="2"
                as={IconButton}
                aria-label="Select tab"
                icon={<MenuIcon />}
                variant="outline"
              />
              <MenuList>
                {tabs.map((option, i) => (
                  <MenuItem
                    key={`tab-menu-item-${option.id}`}
                    onClick={() => {
                      onMenuItemClick(option, i);
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Box>
        ) : null}
      </Box>
    );
  },
);
