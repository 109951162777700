import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  AvatarGroup as ChakraAvatarGroup,
  Flex,
  List,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  useMergeRefs,
} from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import PropTypes from 'prop-types';

import { useResizeEffect } from '../../hooks';

export function useResponsiveAvatarGroup(
  avatarRefs,
  containerRef,
  spacing,
  max,
) {
  let [responsiveMax, setResponsiveMax] = useState(max);
  const updateMax = useCallback(
    (entry) => {
      const contentRect = entry?.contentRect;
      if (!contentRect) return;
      if (!avatarRefs.current) return;

      const width = avatarRefs.current[0].offsetWidth;

      let m = Math.floor(contentRect.width / (width + parseInt(spacing, 10)));
      if (m < avatarRefs.current.length) {
        m--; // subtract the +N avatar
      }
      m = Math.max(m, 1); // m has to be at least 1

      setResponsiveMax(m);
    },
    [avatarRefs, containerRef],
  );

  useLayoutEffect(updateMax, [avatarRefs, containerRef]);

  useResizeEffect(containerRef, updateMax);

  return {
    responsiveMax: max > 0 ? Math.min(responsiveMax, max) : responsiveMax,
  };
}

const AvatarWithTooltip = forwardRef(function AvatarWithTooltip(
  { name, ...props },
  ref,
) {
  return (
    <Tooltip label={name} hasArrow arrowSize="8" borderRadius="base">
      <Box as="div" flex="0">
        <Avatar as="div" showBorder name={name} {...props} ref={ref} />
      </Box>
    </Tooltip>
  );
});

export const AvatarGroup = forwardRef(
  (
    {
      avatars = [],
      label,
      size,
      max,
      align = 'left',
      spacing = '4px',
      ...props
    },
    ref,
  ) => {
    const containerRef = useRef(null);
    const avatarRefs = useRef([]);
    const refs = useMergeRefs(containerRef, ref);
    const { responsiveMax } = useResponsiveAvatarGroup(
      avatarRefs,
      containerRef,
      spacing,
      max,
    );

    return (
      <Popover
        placement={align === 'left' ? 'bottom-start' : 'bottom-end'}
        flip
        isLazy
        preventOverflow
      >
        <PopoverTrigger>
          <Flex
            width="100%"
            ref={refs}
            justifyContent={align === 'left' ? 'flex-start' : 'flex-end'}
          >
            <PopoverAnchor>
              <Button
                aria-label={label}
                size={size}
                variant="unstyled"
                p="0"
                height="auto"
                width="auto"
                maxWidth="auto"
              >
                <ChakraAvatarGroup
                  {...props}
                  max={responsiveMax}
                  spacing={spacing}
                  size={size}
                >
                  {avatars.map((a, index) => (
                    <AvatarWithTooltip
                      key={index}
                      name={a.name}
                      size={size}
                      ref={(ref) => {
                        avatarRefs.current[index] = ref;
                      }}
                    />
                  ))}
                </ChakraAvatarGroup>
              </Button>
            </PopoverAnchor>
          </Flex>
        </PopoverTrigger>
        <PopoverContent maxWidth="100vw">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontSize="sm">{label}</PopoverHeader>
          <PopoverBody>
            <List display="flex" gap="2" flexDirection="column">
              {avatars.map((a, index) => (
                <ListItem
                  key={index}
                  display="flex"
                  gap="4"
                  alignItems="center"
                  fontSize="sm"
                >
                  <Avatar as="div" name={a.name} size="xs" />
                  {a.name}
                </ListItem>
              ))}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);

AvatarGroup.propTypes = {
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  children: PropTypes.node,
  align: PropTypes.oneOf(['left', 'right']),
  spacing: PropTypes.string,
  size: PropTypes.oneOf(['2xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', 'full']),
};
