import axios from 'axios';

import { customParamsSerializer } from './customAxiosParamsSerializer';
import { toastEmitter } from './eventEmitter';

const firehydrantAPI = axios.create({
  baseURL: window.firehydrantBaseAPIPath,
  withCredentials: true,
  paramsSerializer: {
    indexes: null,
    serialize: (params) => {
      return customParamsSerializer(params);
    },
  },
});

firehydrantAPI.interceptors.request.use(
  (config) => {
    const newConfig = Object.assign({}, config);
    newConfig.headers.Accept = 'application/json';
    newConfig.metadata = { startTime: new Date() };

    if (window.location && window.location.pathname) {
      newConfig.headers['x-firehydrant-window-location'] =
        window.location.pathname;
    }

    return newConfig;
  },
  (error) => Promise.reject(error),
);

firehydrantAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      toastEmitter.emit('showGlobalToast', {
        message: 'You do not have the appropriate permissions for this action',
        variant: 'alert',
      });
    }
    return Promise.reject(error);
  },
);

/**
 * Get all pages of a paginated response.
 *
 * @function getAllPages
 * @param    {string}    url    API endpoint to query
 * @param    {object}    params Query parameters to pass to the request
 * @returns  {promise}   Concatenated results for all pages
 */
firehydrantAPI.getAllPages = async (url, params) => {
  const get = async (page = 1) => {
    const response = await firehydrantAPI.get(url, {
      params: { ...params.params, page },
    });
    const {
      data: { data, pagination = null },
    } = response;

    if (pagination && pagination.pages > page) {
      return data.concat(await get(page + 1));
    }

    return data;
  };

  return get(params.page || 1);
};

export const getAPIError = (err, fallbackMessage) => {
  if (err?.response?.data?.messages?.length > 0) {
    return `Error: ${err.response.data.messages.join(', ')}`;
  } else if (err?.response?.data?.detail) {
    if (typeof err.response.data.detail === 'object') {
      // Handle object errors like {"email":["is not a valid email"]}
      return `Error: ${err.response.data.detail.message}`;
    }
    return `Error: ${err.response.data.detail}`;
  } else if (err?.response?.data?.error) {
    if (typeof err.response.data.error === 'object') {
      // Handle object errors like {"email":["is not a valid email"]}
      const keys = Object.keys(err.response.data.error);
      const errors = keys.map(
        (key) => `${key}: ${err.response.data.error[key].join(', ')}`,
      );
      return `Error: ${errors.join(', ')}`;
    } else {
      // Handle simple string error
      return `Error: ${err.response.data.error}`;
    }
  }
  return fallbackMessage || 'An error occurred';
};

export const registrationAPI = axios.create({
  baseURL: window.location.origin,
  withCredentials: true,
});

registrationAPI.interceptors.request.use(
  (config) => {
    const newConfig = Object.assign({}, config);
    newConfig.headers.Accept = 'application/json';

    if (document.querySelector('meta[name=csrf-token]')) {
      newConfig.headers['X-CSRF-Token'] = document.querySelector(
        'meta[name=csrf-token]',
      ).content;
    }

    return newConfig;
  },
  (error) => Promise.reject(error),
);

export default firehydrantAPI;
