import React from 'react';

import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { colorOptions } from '../../theme/colors.theme';

export const MultiSelectIcon = (props) => (
  <Icon boxSize="16px" viewBox="0 0 16 16" {...props}>
    <path
      d="M16 4.42768H8.8V6.02768H16V4.42768ZM16 10.8277H8.8V12.4277H16V10.8277ZM2.832 7.62768L0 4.79568L1.128 3.66768L2.824 5.36368L6.216 1.97168L7.344 3.09968L2.832 7.62768ZM2.832 14.0277L0 11.1957L1.128 10.0677L2.824 11.7637L6.216 8.37168L7.344 9.49968L2.832 14.0277Z"
      fill="currentColor"
    />
  </Icon>
);

MultiSelectIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
