import { useNavigate, useParams } from 'react-router-dom';

export const useOrgNavigate = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();

  return (to, ...rest) => {
    let path = to;

    if (typeof to === 'string' && to?.charAt(0) === '/' && orgId) {
      path = `/org/${orgId}${to}`;
    }

    navigate(path, ...rest);
  };
};
