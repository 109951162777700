import React from 'react';

import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { colorOptions } from '../../theme/colors.theme';

export const SingleSelectIcon = (props) => (
  <Icon boxSize="16px" viewBox="0 0 16 16" {...props}>
    <path
      d="M4.49474 11.2135L0.701677 7.42043L-0.589966 8.70298L4.49474 13.7877L15.41 2.87239L14.1275 1.58984L4.49474 11.2135Z"
      fill="currentColor"
    />
  </Icon>
);

SingleSelectIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
