import React from 'react';

import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  VisuallyHidden,
  useMediaQuery,
} from '@chakra-ui/react';
import { Confetti } from '@phosphor-icons/react';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { IncidentListItem } from './IncidentListItem';

export function IncidentList({
  isLoading = false,
  emptyMessage = '',
  children,
  ...props
}) {
  const [renderAsOneCol] = useMediaQuery(`(max-width: 800px)`);
  const numItems = React.Children.toArray(children).length;
  const animationProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };
  return (
    <Box minHeight={isLoading ? '200px' : null}>
      <Table
        display={renderAsOneCol ? 'flex' : 'table'}
        flexDirection="column"
        gap="2"
        {...props}
        variant="dashboard"
      >
        {!renderAsOneCol && (
          <Thead>
            <Tr display={renderAsOneCol ? 'flex' : 'table-row'}>
              <VisuallyHidden as="th" scope="col" width="1px">
                Severity
              </VisuallyHidden>
              <VisuallyHidden as="th" scope="col">
                Incident details
              </VisuallyHidden>
              <VisuallyHidden as="th" scope="col" width="1px">
                Milestone and links
              </VisuallyHidden>
            </Tr>
          </Thead>
        )}
        <Tbody>
          <AnimatePresence>
            {isLoading
              ? new Array(5)
                  .fill(null)
                  .map((x, i) => (
                    <IncidentListItem
                      key={`loading-${i}`}
                      renderAsOneCol={renderAsOneCol}
                    />
                  ))
              : React.Children.map(children, (child) => {
                  if (!React.isValidElement(child)) {
                    return null;
                  }
                  return React.cloneElement(child, {
                    as: motion.tr,
                    key: child.props?.incident?.id,
                    ...animationProps,
                    renderAsOneCol,
                  });
                })}

            {numItems <= 0 && !isLoading && (
              <Tr
                as={motion.tr}
                {...animationProps}
                exit={{
                  opacity: 0,
                  transition: { duration: 0 },
                }}
                display={renderAsOneCol ? 'flex' : 'table-row'}
                flexDirection="column"
                key="empty-state"
              >
                <Td display={['block', null, 'table-cell']} colspan="3">
                  <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    gap="2"
                    py="6"
                    px="2"
                    fontWeight="semibold"
                    fontSize="lg"
                  >
                    <Box color="primary.600">
                      <Confetti size={100} weight="duotone" />
                    </Box>
                    <Box display="block">{emptyMessage}</Box>
                  </Flex>
                </Td>
              </Tr>
            )}
          </AnimatePresence>
        </Tbody>
      </Table>
    </Box>
  );
}
IncidentList.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
