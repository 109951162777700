import { colors } from '../../theme/colors.theme';

export const TextField = {
  baseStyle: {
    fontSize: 5,
    lineHeight: 5,
    fontWeight: 5,
  },
  sizes: {
    md: {
      field: {
        border: '1px solid',
        borderColor: 'inherit',
        py: 2,
        px: 3,
      },
    },
  },
  variants: {
    search: {
      field: {
        border: '1px',
        borderColor: 'grey.200',
        borderRadius: '2px',
        boxShadow: 'none',
        py: '2',
        pr: '2',
        pl: '25px',
        _focus: { borderColor: 'grey.700' },
        _active: { borderColor: 'grey.900' },
        _placeholder: { color: '#6F6F6F', fontWeight: '400' },
      },
    },
    outline: {
      field: {
        bg: 'grey.0',
        color: 'grey.900',
        border: 'none',
        borderRadius: 'base',
        boxShadow: `0px 0px 0px 1px ${colors.grey[200]} inset`,
        _readOnly: {
          userSelect: 'all',
          color: 'grey.700',
          bg: 'grey.0',
          boxShadow: `0px 0px 0px 1px ${colors.grey[500]} inset`,
          _active: {
            boxShadow: `0px 0px 0px 1px ${colors.grey[500]} inset`,
            _focusVisible: {
              boxShadow: `0px 0px 0px 1px ${colors.grey[500]} inset`,
            },
          },
          _focusVisible: {
            boxShadow: `0px 0px 0px 1px ${colors.grey[500]} inset`,
          },
        },
        _invalid: {
          boxShadow: `0px 0px 0px 1px ${colors.red[500]} inset`,
          _active: {
            boxShadow: `0px 0px 0px 1px ${colors.red[500]} inset`,
            border: 'none',
            _focusVisible: {
              boxShadow: `0px 0px 0px 1px ${colors.red[500]} inset`,
              border: 'none',
            },
          },
          _focusVisible: {
            boxShadow: `${colors.red[500]} 0px 0px 0px 1px`,
          },
        },
        _active: {
          boxShadow: `0px 0px 0px 1px ${colors.primary[500]} inset`,
          _focusVisible: {
            boxShadow: `0px 0px 0px 1px ${colors.primary[500]} inset`,
          },
        },
        _hover: {
          boxShadow: `0px 0px 0px 1px ${colors.gray[300]} inset`,
        },
        _focusVisible: {
          boxShadow: `${colors.primary[500]} 0px 0px 0px 1px`,
        },
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};
