import React from 'react';

import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { colorOptions } from '../../theme/colors.theme';

export const VictorOpsIcon = (props) => (
  <Icon boxSize="24px" viewBox="0 0 64 64" {...props}>
    <path
      d="M25.92 45.6l5.36 6.24v7.36h1.36v-7.36l5.28-6.24-1.92.88.4-1.36 4.48-2 .24-1.44 3.6-4.56-7.12 2.32.16-1.6 9.44-4.8-2.08-3.68 4.4-6.32-12.32 5.92-.24-1.76 3.6-5.92-.48-.96 1.6-6.88-6.4 6.8-.4-3.76 1.92-5.68-2.56.88-2.32-6.88-2.24 6.88-2.56-.88 1.92 5.68-.4 3.76-6.48-6.8 1.68 6.88-.48.96 3.6 5.92-.24 1.76-12.4-5.92 4.48 6.32-2.08 3.68 9.44 4.8.16 1.6-7.2-2.32 3.6 4.56.32 1.44 4.4 2 .48 1.36z"
      fill="currentColor"
    />
  </Icon>
);

VictorOpsIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
