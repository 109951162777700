import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const IncidentsOutlineIcon = (props) => (
  <Icon boxSize="24px" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M12.639 3.778a6.61 6.61 0 00.03 1.753h0v.005c.211 1.399.835 2.595 1.86 3.551l.017.015.017.015c.3.255.74.612 1.314 1.065l.025.02.025.017c.469.325.79.56.979.71l.127.123c.335.326.546.548.657.678.404.56.627.943.722 1.173l.031.076.043.07.118.19a5.27 5.27 0 01.243 3.27c-.27 1.108-.852 2.059-1.776 2.867h0l-.008.007c-.757.677-1.67 1.141-2.765 1.384h0l-.013.003c-1.051.248-2.16.297-3.333.139h0l-.013-.002c-.99-.12-1.94-.45-2.855-1-.877-.58-1.568-1.27-2.09-2.068-.352-.627-.573-1.173-.687-1.644l-.014-.059-.022-.056c-.096-.258-.192-.706-.26-1.395a6.827 6.827 0 01.408-2.724 6.1 6.1 0 001.199 2.59c.102.16.234.293.389.396a1.584 1.584 0 001.656.28c.269-.102.548-.274.759-.555.221-.296.306-.633.306-.95 0-.298-.09-.562-.191-.77-.583-1.516-.66-3.07-.221-4.69.48-1.64 1.361-2.97 2.655-4.017.228-.173.45-.329.668-.467z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </Icon>
);

IncidentsOutlineIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
