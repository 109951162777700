import React from 'react';

import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { colorOptions } from '../../theme/colors.theme';

export const PlayIcon = (props) => (
  <Icon boxSize="16px" viewBox="0 0 16 16" {...props}>
    <path
      d="M14.525 7.15569L5.52 1.64694C5.36818 1.55398 5.19429 1.50322 5.0163 1.49993C4.83831 1.49663 4.66266 1.54091 4.5075 1.62819C4.35382 1.71412 4.2258 1.83943 4.1366 1.99124C4.04741 2.14305 4.00026 2.31587 4 2.49194V13.5082C4.00116 13.7723 4.10715 14.0252 4.29466 14.2111C4.48218 14.3971 4.73589 14.5011 5 14.5001C5.18435 14.5 5.36511 14.4492 5.5225 14.3532L14.525 8.84444C14.6697 8.75624 14.7893 8.63231 14.8723 8.48454C14.9553 8.33678 14.9989 8.17016 14.9989 8.00069C14.9989 7.83122 14.9553 7.6646 14.8723 7.51684C14.7893 7.36908 14.6697 7.24514 14.525 7.15694V7.15569ZM5 13.4963V2.50007L13.9894 8.00007L5 13.4963Z"
      fill="currentColor"
    />
  </Icon>
);

PlayIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
