import { useEffect } from 'react';

export default function pawPrints({ name, params = {} }) {
  if (window.analytics && name) {
    window.analytics.track(name, params);
  }
}

// makes it easier to import and use the function
export const trackEvent = ({ name, params }) => {
  pawPrints({ name, params });
};

export const useTrackEventOnload = ({ name, params }) => {
  useEffect(() => {
    pawPrints({ name, params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
