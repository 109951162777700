import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const LockIcon = (props) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 18.031c.561 0 1.03-.187 1.403-.562.406-.407.609-.89.609-1.453 0-.563-.203-1.032-.609-1.407A1.829 1.829 0 0012 14c-.561 0-1.045.203-1.45.61-.375.374-.562.843-.562 1.406 0 .562.187 1.047.562 1.453.405.375.889.562 1.45.562zm5.988-9c.562 0 1.03.203 1.404.61.405.375.608.828.608 1.359v10.031c0 .532-.203.985-.608 1.36-.375.406-.842.609-1.404.609H6.012a1.978 1.978 0 01-1.45-.61A1.854 1.854 0 014 21.032V11c0-.531.187-.984.561-1.36.406-.406.89-.609 1.45-.609h.983V7.016c0-.907.218-1.735.655-2.485a5.119 5.119 0 011.825-1.828A4.817 4.817 0 0112 2c.905 0 1.731.234 2.48.703a4.67 4.67 0 011.824 1.828c.468.75.702 1.578.702 2.485V9.03h.982zM12 4.016c-.842 0-1.56.296-2.152.89-.561.563-.842 1.266-.842 2.11V9.03h5.988V7.016c0-.844-.296-1.547-.889-2.11-.561-.593-1.263-.89-2.105-.89z"
      fill="currentColor"
    />
  </Icon>
);

LockIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
