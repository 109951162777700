import React from 'react';

import { Icon } from '@chakra-ui/react';

export const GroupIcon = (props) => (
  <Icon boxSize="14px" viewBox="0 0 14 14" {...props}>
    <path
      d="M3.20817 4.9585L5.24984 7.00016L3.20817 9.04183L1.1665 7.00016L3.20817 4.9585Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="white"
    />
    <path
      d="M7.00016 1.1665L9.04183 3.20817L7.00016 5.24984L4.9585 3.20817L7.00016 1.1665Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="white"
    />
    <path
      d="M10.7917 4.9585L12.8333 7.00016L10.7917 9.04183L8.75 7.00016L10.7917 4.9585Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="white"
    />
    <path
      d="M7.00016 8.75L9.04183 10.7917L7.00016 12.8333L4.9585 10.7917L7.00016 8.75Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="white"
    />
  </Icon>
);
