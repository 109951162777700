import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ComponentIcon = (props) => (
  <Icon boxSize="14px" viewBox="0 0 14 14" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66983 1.39011C6.85207 1.20785 7.14755 1.20785 7.3298 1.39011L12.6096 6.66983C12.7917 6.85208 12.7917 7.14756 12.6096 7.3298L7.3298 12.6095C7.14755 12.7918 6.85207 12.7918 6.66983 12.6095L1.3901 7.3298C1.20786 7.14756 1.20786 6.85208 1.3901 6.66983L6.66983 1.39011ZM6.99981 2.38006L2.38005 6.99981L6.99981 11.6195L11.6196 6.99981L6.99981 2.38006Z"
      fill="currentColor"
    />
  </Icon>
);
