import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const OrganizationFilledIcon = (props) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 4c.969 0 1.797.378 2.484 1.135.688.757 1.032 1.67 1.032 2.736 0 1.067-.344 1.978-1.032 2.736-.687.756-1.515 1.135-2.484 1.135-.969 0-1.797-.379-2.484-1.136-.688-.757-1.032-1.668-1.032-2.735s.344-1.979 1.032-2.736C10.203 4.378 11.03 4 12 4zM5.016 6.787c.53 0 1.03.155 1.5.465-.063.757 0 1.514.187 2.27a7.57 7.57 0 00.938 2.065c-.25.55-.61.998-1.079 1.342-.468.31-.984.464-1.546.464-.844 0-1.563-.326-2.157-.98-.562-.654-.843-1.428-.843-2.323 0-.929.28-1.703.843-2.322.594-.654 1.313-.98 2.157-.98zm13.968 0c.844 0 1.547.327 2.11.98.593.62.89 1.394.89 2.323a3.34 3.34 0 01-.89 2.323c-.563.654-1.266.98-2.11.98a2.748 2.748 0 01-1.547-.464 3.35 3.35 0 01-1.078-1.342c.407-.62.703-1.307.891-2.064a6.261 6.261 0 00.234-2.271c.47-.31.97-.465 1.5-.465zM5.484 18.04c0-.723.297-1.394.891-2.013.594-.654 1.375-1.17 2.344-1.549 1-.378 2.094-.567 3.281-.567 1.188 0 2.266.189 3.234.567 1 .379 1.797.895 2.391 1.549.594.62.89 1.29.89 2.013V20H5.486v-1.961zM0 20v-1.652c0-.757.422-1.428 1.266-2.012.843-.62 1.906-1.016 3.187-1.188-.625.792-.937 1.755-.937 2.89V20H0zm24 0h-3.516v-1.961c0-1.136-.312-2.1-.937-2.89 1.281.171 2.344.567 3.187 1.187.844.584 1.266 1.255 1.266 2.012V20z"
      fill="currentColor"
    />
  </Icon>
);

OrganizationFilledIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
