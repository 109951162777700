import React, { useContext } from 'react';

import AuthContext from './AuthContext';

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const handleLogin = () => {
    window.localStorage.setItem('logged_in', true);
  };

  const handleLogout = () => {
    window.localStorage.setItem('logged_in', false);
    window.location.reload(true);
  };

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
