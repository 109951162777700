import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const SuccessIcon = (props) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 2C10.1847 2 8.51017 2.45383 6.97653 3.3615C5.44288 4.23787 4.22222 5.44288 3.31455 6.97653C2.43818 8.51017 2 10.1847 2 12C2 13.8153 2.43818 15.4898 3.31455 17.0235C4.22222 18.5571 5.44288 19.7778 6.97653 20.6854C8.51017 21.5618 10.1847 22 12 22C13.8153 22 15.4898 21.5618 17.0235 20.6854C18.5571 19.7778 19.7621 18.5571 20.6385 17.0235C21.5462 15.4898 22 13.8153 22 12C22 10.1847 21.5462 8.51017 20.6385 6.97653C19.7621 5.44288 18.5571 4.23787 17.0235 3.3615C15.4898 2.45383 13.8153 2 12 2ZM12 20.0282C10.5603 20.0282 9.2144 19.6682 7.96244 18.9484C6.74178 18.2285 5.77152 17.2582 5.05164 16.0376C4.33177 14.7856 3.97183 13.4397 3.97183 12C3.97183 10.5603 4.33177 9.23005 5.05164 8.00939C5.77152 6.75743 6.74178 5.77152 7.96244 5.05164C9.2144 4.33177 10.5603 3.97183 12 3.97183C13.4397 3.97183 14.77 4.33177 15.9906 5.05164C17.2426 5.77152 18.2285 6.75743 18.9484 8.00939C19.6682 9.23005 20.0282 10.5603 20.0282 12C20.0282 13.4397 19.6682 14.7856 18.9484 16.0376C18.2285 17.2582 17.2426 18.2285 15.9906 18.9484C14.77 19.6682 13.4397 20.0282 12 20.0282ZM16.6009 7.58685L9.98122 14.1596L7.39906 11.5775L5.99061 12.9859L9.98122 17.0235L18.0094 8.99531L16.6009 7.58685Z"
      fill="currentColor"
    />
  </Icon>
);

SuccessIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
