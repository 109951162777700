import React from 'react';

import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { colorOptions } from '../../theme/colors.theme';

export const EmailAltIcon = (props) => (
  <Icon boxSize="16px" viewBox="0 0 16 16" {...props}>
    <path
      d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16H12V14.4H8C4.528 14.4 1.6 11.472 1.6 8C1.6 4.528 4.528 1.6 8 1.6C11.472 1.6 14.4 4.528 14.4 8V9.144C14.4 9.776 13.832 10.4 13.2 10.4C12.568 10.4 12 9.776 12 9.144V8C12 5.792 10.208 4 8 4C5.792 4 4 5.792 4 8C4 10.208 5.792 12 8 12C9.104 12 10.112 11.552 10.832 10.824C11.352 11.536 12.248 12 13.2 12C14.776 12 16 10.72 16 9.144V8C16 3.584 12.416 0 8 0ZM8 10.4C6.672 10.4 5.6 9.328 5.6 8C5.6 6.672 6.672 5.6 8 5.6C9.328 5.6 10.4 6.672 10.4 8C10.4 9.328 9.328 10.4 8 10.4Z"
      fill="currentColor"
    />
  </Icon>
);

EmailAltIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
