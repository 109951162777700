import React from 'react';

import { Box, Icon, IconButton, Skeleton, Tooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { LuNfc } from 'react-icons/lu';

export function IncidentStatusPageLink({
  url,
  isLoading = false,
  preserveSpace = false,
  ...props
}) {
  if (isLoading === false && !url) {
    return preserveSpace ? <Box width="24px" /> : null;
  }

  return (
    <Skeleton
      isLoaded={url || isLoading === false}
      display="inline-flex"
      {...props}
    >
      <Tooltip label="Private status page" hasArrow fontSize="xs">
        <IconButton
          as="a"
          variant="ghost"
          size="xs"
          icon={<Icon as={LuNfc} boxSize="16px" />}
          href={url}
          target="_blank"
          rel="noopener"
          color="gray.900"
          aria-label="Private Status Page"
        />
      </Tooltip>
    </Skeleton>
  );
}
IncidentStatusPageLink.propTypes = {
  isLoading: PropTypes.bool,
  url: PropTypes.string,
  preserveSpace: PropTypes.bool,
};
