import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const IntegrationsOutlineIcon = (props) => (
  <Icon boxSize="24px" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M22 13.502c0 .877-.282 1.643-.845 2.3-.563.658-1.283 1.05-2.16 1.175v3.051c0 .532-.203.986-.61 1.362-.376.407-.83.61-1.362.61h-3.802v-.282c0-.75-.266-1.392-.799-1.925a2.623 2.623 0 00-1.924-.798c-.752 0-1.393.266-1.925.798a2.623 2.623 0 00-.798 1.925V22H3.972c-.532 0-1.002-.203-1.409-.61A1.855 1.855 0 012 20.028v-3.803h.282c.75 0 1.392-.266 1.925-.798a2.623 2.623 0 00.798-1.925c0-.75-.266-1.393-.798-1.925a2.623 2.623 0 00-1.925-.798H2V6.977c0-.533.188-.986.563-1.362.407-.407.877-.61 1.409-.61h3.051c.126-.877.517-1.597 1.174-2.16a3.426 3.426 0 012.3-.845c.877 0 1.644.282 2.301.845.657.563 1.049 1.283 1.174 2.16h3.051c.533 0 .986.203 1.362.61.407.376.61.83.61 1.362v3.051c.877.125 1.597.517 2.16 1.174.563.657.845 1.424.845 2.3zm-4.977 1.503h1.503c.407 0 .751-.141 1.033-.423.313-.313.47-.673.47-1.08 0-.407-.157-.75-.47-1.033A1.337 1.337 0 0018.526 12h-1.503V6.977H12V5.474c0-.407-.156-.751-.47-1.033a1.337 1.337 0 00-1.032-.47c-.407 0-.767.157-1.08.47a1.405 1.405 0 00-.423 1.033v1.503H3.972v2.16a4.456 4.456 0 012.16 1.69c.563.782.845 1.674.845 2.675 0 1.002-.282 1.894-.846 2.676a4.455 4.455 0 01-2.16 1.69v2.16h2.16a4.456 4.456 0 011.69-2.16c.783-.563 1.675-.845 2.677-.845 1.001 0 1.893.282 2.676.846a4.456 4.456 0 011.69 2.16h2.16v-5.024z"
      fill="currentColor"
    />
  </Icon>
);

IntegrationsOutlineIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
