import React from 'react';

import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FiTrash } from 'react-icons/fi';

import { colorOptions } from '../../theme/colors.theme';

export const DeleteIcon = (props) => {
  return <Icon as={FiTrash} color="grey.800" {...props}></Icon>;
};

DeleteIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
