import { GraphQLClient } from 'graphql-request';

const tokenDom = document.querySelector('meta[name=csrf-token]');

const internalGQL = (token) => {
  return new GraphQLClient('/_graphql', {
    fetch,
    headers: {
      'X-CSRF-Token': tokenDom?.content,
      ...(token && { authorization: `Bearer ${token}` }),
    },
    credentials: 'include',
  });
};

export default internalGQL;
