const gray = {
  0: '#ffffff',
  50: '#F3F4F7',
  100: '#EAECF0',
  200: '#DCDDE0',
  300: '#C5C8D8',
  400: '#B6B9C9',
  500: '#A4A8BC',
  600: '#6C6F7F',
  700: '#464A53',
  800: '#343842',
  900: '#2B2F36',
  1000: '#25272E',
  1100: '#202228',
};

const red = {
  50: '#FCEDED',
  100: '#F8D5D3',
  200: '#FACCCC',
  300: '#FF8A8A',
  400: '#EF726B',
  500: '#E4544E',
  600: '#DC3931',
  700: '#B72F29',
  800: '#932621',
  900: '#701D19',
  1000: '#451D1C',
  1100: '#300C0B',
};

const oceanBlue = {
  50: '#EAF1FE',
  100: '#DCE8FD',
  200: '#C0D6FB',
  300: '#95BAF9',
  400: '#71A2F8',
  500: '#4C8AF6',
  600: '#1767F2',
  700: '#175BD1',
  800: '#1349A7',
  900: '#0E387F',
  1000: '#142A4F',
  1100: '#061735',
};

const colorButtonPrimaryDefault = oceanBlue['600'];
const colorButtonPrimaryHover = oceanBlue['700'];
const colorButtonPrimaryActive = oceanBlue['800'];
const colorButtonPrimaryGhostHover = oceanBlue['50'];
const colorButtonPrimaryGhostActive = oceanBlue['100'];

export const colors = {
  grey: gray,
  gray,
  red,
  oceanBlue,

  'button-primary': {
    50: colorButtonPrimaryGhostHover,
    100: colorButtonPrimaryGhostActive,
    200: colorButtonPrimaryHover,
    300: oceanBlue['300'],
    400: colorButtonPrimaryHover,
    500: colorButtonPrimaryDefault,
    600: colorButtonPrimaryHover,
    700: colorButtonPrimaryActive,
  },

  'button-danger': red,

  'color-tabs-primary': oceanBlue,

  primary: {
    50: '#EAF1FE',
    100: '#DCE8FD',
    200: '#C0D6FB',
    300: '#95BAF9',
    400: '#71A2F8',
    500: '#4C8AF6',
    600: '#1767F2',
    700: '#175BD1',
    800: '#1349A7',
    900: '#0E387F',
    1000: '#142A4F',
    1100: '#061735',
  },

  blue: {
    50: '#E2F3FE',
    100: '#CFEBFD',
    200: '#9CD7FC',
    300: '#68C2FA',
    400: '#2EAAF8',
    500: '#1192E3',
    600: '#0E7CC1',
    700: '#0C67A0',
    800: '#1D4D6C',
    900: '#073F61',
    1000: '#0C2A3D',
    1100: '#031A29',
  },

  purple: {
    50: '#F2EFFC',
    100: '#E9E4FA',
    200: '#D3CAF6',
    300: '#BEB0F1',
    400: '#A996ED',
    500: '#937BE8',
    600: '#7D61E3',
    700: '#6646DA',
    800: '#5238AE',
    900: '#3E2B84',
    1000: '#2A2343',
    1100: '#1A1237',
  },

  green: {
    50: '#E8F3EE',
    100: '#CFE7DC',
    200: '#BBDDCE',
    300: '#8CC5AA',
    400: '#66B18E',
    500: '#3E9D71',
    600: '#2C865C',
    700: '#246F4C',
    800: '#1D593D',
    900: '#1D593D',
    1000: '#16432E',
    1100: '#152920',
  },

  yellow: {
    50: '#FEF5E2',
    100: '#FBE7B6',
    200: '#FBE2A9',
    300: '#FAD88C',
    400: '#F8CF70',
    500: '#ECAB18',
    600: '#BA9031',
    700: '#866A2D',
    800: '#765B1E',
    900: '#443717',
    1000: '#231F14',
    1100: '#1D1809',
  },

  orange: {
    50: '#FEEEE3',
    100: '#FCDAC4',
    200: '#FFD3B8',
    300: '#FFA96B',
    400: '#F88238',
    500: '#E67228',
    600: '#BD5B1D',
    700: '#9D4C18',
    800: '#7D3C13',
    900: '#5F2E0F',
    1000: '#3B2212',
    1100: '#271306',
  },

  teal: {
    // making teal the same as blue for now
    50: '#E2F3FE',
    100: '#CFEBFD',
    200: '#9CD7FC',
    300: '#68C2FA',
    400: '#2EAAF8',
    500: '#1192E3',
    600: '#0E7CC1',
    700: '#0C67A0',
    800: '#1D4D6C',
    900: '#073F61',
    1000: '#0C2A3D',
    1100: '#031A29',
  },

  beige: {
    50: '#F1F0F0',
    100: '#E8E7E6',
    200: '#D2CFCC',
    300: '#BCB8B4',
    400: '#A7A19C',
    500: '#928B85',
    600: '#7E766E',
    700: '#69615A',
    800: '#544D47',
    900: '#3F3B36',
    1000: '#2C2926',
    1100: '#1A1816',
  },

  magenta: {
    50: '#fcdbe3',
    100: '#fbc1cf',
    200: '#f898af',
    300: '#eb528d',
    400: '#de247e',
    500: '#c5006c',
    600: '#b90068',
    700: '#880056',
    800: '#640040',
    900: '#49002f',
  },

  // All deprecated colors below

  // Side navigation
  grey_deprecated: {
    90: '#182042',
    70: '#536685',
    50: '#8a9bb7',
    30: '#c6d0e2',
    10: '#eef1f6',
    0: '#ffffff',
  },

  // GraidentButton/Registration
  orange_deprecated: {
    90: '#933600',
    70: '#d14900',
    50: '#ff5300',
    30: '#ff9862',
    10: '#ffece3',
  },

  purple_deprecated: {
    90: '#220e6d',
    70: '#3b2492',
    50: '#614ab6',
    30: '#b2a6e3',
    10: '#f1edff',
  },

  magenta_deprecated: {
    90: '#791b96',
    70: '#a3008b',
    50: '#c5007b',
    30: '#e00066',
  },

  // used in Link component
  gradient:
    'linear(to-r, purple_deprecated.70, magenta_deprecated.90 19.27%, magenta_deprecated.70 36.46%, magenta_deprecated.50 56.77%, magenta_deprecated.30 75.52%, orange_deprecated.50)',
};

export const colorKeys = (color) =>
  Object.keys(colors[color]).reduce(
    (values, currentValue) => ({
      ...values,
      [`${color}.${currentValue}`]: colors[color][currentValue],
    }),
    {},
  );

export const colorOptions = Object.keys(colors).reduce((acc, color) => {
  return [
    ...acc,
    ...Object.keys(colors[color]).reduce((values, num) => {
      return [...values, `${color}.${num}`];
    }, []),
  ];
}, []);
