export const space = {
  mg0: '2px',
  mg1: '6px',
  mg2: '10px',
  0: '0px',
  1: '4px',
  2: '8px',
  3: '16px',
  4: '24px',
  5: '32px',
  6: '40px',
  7: '48px',
  8: '56px',
};
