import React from 'react';

import { Skeleton, Tag } from '@chakra-ui/react';
import { LIFE_CYCLE_COLORS } from 'helpers/lifecycleHelpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { formattedMilestones } from '../../../../fh-constants/milestones';

export function IncidentMilestoneBadge({ isLoading = false, incident }) {
  const { customMilestones } = useFlags();
  const currentMilestone = incident?.current_milestone;

  const phaseWithCurrentMilestone = incident?.lifecycle_phases?.find((phase) =>
    phase.milestones.some((milestone) => milestone.slug === currentMilestone),
  );

  const currentMilestoneData = phaseWithCurrentMilestone?.milestones?.find(
    (milestone) => milestone.slug === currentMilestone,
  );

  const milestoneLabel =
    customMilestones && currentMilestoneData?.name
      ? currentMilestoneData.name
      : formattedMilestones[currentMilestone]?.label;

  const milestoneColor =
    customMilestones && currentMilestoneData?.slug
      ? LIFE_CYCLE_COLORS[phaseWithCurrentMilestone.type?.toUpperCase()]
      : formattedMilestones[currentMilestone]?.color;

  if (isLoading === false && !currentMilestone) {
    return null;
  }

  return (
    <Skeleton
      isLoaded={currentMilestone || isLoading === false}
      borderRadius="full"
      maxWidth="20ch"
      display="inline-block"
    >
      <Tag variant="milestone" colorScheme={milestoneColor}>
        {milestoneLabel}
      </Tag>
    </Skeleton>
  );
}
IncidentMilestoneBadge.propTypes = {
  isLoading: PropTypes.bool,
  incident: PropTypes.shape({
    current_milestone: PropTypes.string,
    lifecycle_phases: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        milestones: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string,
          }),
        ),
      }),
    ),
    milestones: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        occurred_at: PropTypes.string,
      }),
    ),
  }),
};
