import PropTypes from 'prop-types';

export const IncidentPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  severity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  milestone: PropTypes.string,
  role: PropTypes.string,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  links: PropTypes.shape({
    slackChannel: PropTypes.string,
    msTeamsChannel: PropTypes.string,
    privateStatusPage: PropTypes.resetWarningCache,
  }),
  isWatched: PropTypes.bool,
  isPrivate: PropTypes.bool,
});
