import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const SearchIcon = (props) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props}>
    <path
      d="M9.70777 3C10.8981 3 12.008 3.30563 13.0375 3.91689C14.067 4.49598 14.8713 5.30027 15.4504 6.32976C16.0617 7.35925 16.3673 8.48525 16.3673 9.70777C16.3673 11.3485 15.8365 12.7962 14.7748 14.0509L15.0643 14.3405H15.8847L21 19.4558L19.4558 21L14.3405 15.8847V15.0643L14.0509 14.7748C12.7962 15.8365 11.3485 16.3673 9.70777 16.3673C8.48525 16.3673 7.35925 16.0777 6.32976 15.4987C5.30027 14.8874 4.47989 14.067 3.86863 13.0375C3.28954 12.008 3 10.8981 3 9.70777C3 8.48525 3.28954 7.35925 3.86863 6.32976C4.47989 5.30027 5.30027 4.49598 6.32976 3.91689C7.35925 3.30563 8.48525 3 9.70777 3ZM9.70777 5.07507C8.42091 5.07507 7.32708 5.52547 6.42627 6.42627C5.52547 7.32708 5.07507 8.42091 5.07507 9.70777C5.07507 10.9946 5.52547 12.0885 6.42627 12.9893C7.32708 13.8901 8.42091 14.3405 9.70777 14.3405C10.9946 14.3405 12.0885 13.8901 12.9893 12.9893C13.8901 12.0885 14.3405 10.9946 14.3405 9.70777C14.3405 8.42091 13.8901 7.32708 12.9893 6.42627C12.0885 5.52547 10.9946 5.07507 9.70777 5.07507Z"
      fill="currentColor"
    />
  </Icon>
);

SearchIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
