import React from 'react';

import { Box, useToken } from '@chakra-ui/react';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import PropTypes from 'prop-types';

export function SparklineChart({ isLoading = false, label, data }) {
  const defaultColors = [
    'blue.500',
    'blue.100',
    'grey.300',
    'grey.50',
    'white',
  ];

  const [primaryColor, secondaryColor, darkGrey, lightGrey, white] = useToken(
    'colors',
    defaultColors,
    ['gray', 'lightgray', 'gray', 'lightgray', 'white'],
  );

  const hasActivity = data?.[0].data?.find((coordinate) => coordinate.y > 0);
  const chartColor = isLoading ? darkGrey : primaryColor;
  const gradientColor = isLoading ? lightGrey : secondaryColor;
  const chartHeight = '2.5em';

  return (
    <Box width="100%" color={chartColor}>
      {isLoading || !hasActivity ? (
        <Box
          height={chartHeight}
          borderBottom="solid 2px"
          borderColor={chartColor}
          bgGradient={`linear(180deg, ${gradientColor}, ${white})`}
          backgroundSize="100% 80%"
          backgroundRepeat="no-repeat"
        />
      ) : (
        <Box
          width="100%"
          height={chartHeight}
          sx={{
            svg: {
              overflow: 'visible !important',
            },
          }}
        >
          <ResponsiveLine
            data={data}
            colors={[chartColor]}
            enableArea={true}
            enablePoints={false}
            enableGrid={false}
            enableCrosshair={false}
            isInteractive={false}
            animate={false}
            curve="basis"
            defs={[
              linearGradientDef('gradientA', [
                { offset: 0, color: 'inherit', opacity: 1 },
                { offset: 100, color: white, opacity: 1 },
              ]),
            ]}
            fill={[{ match: '*', id: 'gradientA' }]}
            layers={['areas', 'lines']}
          />
        </Box>
      )}
      <Box pt="1px" minHeight="1em" fontSize="2xs" textTransform="uppercase">
        {label}
      </Box>
    </Box>
  );
}
SparklineChart.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          y: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        }),
      ),
    }),
  ),
};
