import React from 'react';

import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { colorOptions } from '../../theme/colors.theme';

export const StringIcon = (props) => (
  <Icon boxSize="16px" viewBox="0 0 16 16" {...props}>
    <path d="M0 5H16V7H0V5ZM0 9H10V11H0V9Z" fill="currentColor" />
  </Icon>
);

StringIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
