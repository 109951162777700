import { useLocation, useMatch } from 'react-router-dom';

// Ignores `/org/:id` from the location pathname
export const useMultiOrgPaths = () => {
  const location = useLocation();
  const multiOrgPathMatch = useMatch('/org/:orgId/*');
  const pathParts = location.pathname.split('/');

  const paths = multiOrgPathMatch
    ? pathParts.filter((x, index) => index !== 1 && index !== 2)
    : pathParts;

  const formattedPathname = paths.join('/');

  return formattedPathname;
};

// Grabs organizationId from the URL outside of the Router context
export const orgIdFromLocation = () => {
  const path = window.location.pathname;
  if (path.match('/org/')) {
    const orgId = path.split('/')[2];
    return orgId;
  }

  return null;
};
