import React from 'react';

import { Icon, forwardRef } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { colorOptions } from '../../theme/colors.theme';

export const LightningIcon = forwardRef((props, ref) => (
  <Icon boxSize="14px" viewBox="0 0 14 14" {...props} ref={ref}>
    <path
      d="M7.58334 5.25H12.25L6.41668 14V8.75H2.33334L7.58334 0V5.25ZM6.41668 6.41667V4.21167L4.39368 7.58333H7.58334V10.1465L10.0701 6.41667H6.41668Z"
      fill="currentColor"
    />
  </Icon>
));

LightningIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
