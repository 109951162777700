const containerStyles = {
  backgroundColor: 'grey.0',
  borderRadius: 'lg',
  color: 'inherit',
  borderWidth: '2px',
  boxShadow: 'none',
  border: '1px solid var(--chakra-colors-grey-100)',
};

export const Card = {
  baseStyle: {
    container: {
      ...containerStyles,
    },
    ...containerStyles,
    p: 4,
  },
  variants: {
    primary: {
      container: {
        ...containerStyles,
      },
    },
    callout: {
      container: {
        bgColor: 'oceanBlue.50',
        border: '1px dashed var(--chakra-colors-oceanBlue-300)',
        p: 4,
      },
    },
  },
};

export const CardHeader = {
  baseStyle: {
    mb: 3,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
