import { colorOptions } from '../../theme/colors.theme';
import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const RocketIcon = (props) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props}>
    <path
      d="M13.206 22l-1.628-3.767a21.046 21.046 0 004.37-2.28L13.207 22zm-7.44-9.581L2 10.79l6.044-2.744a21.052 21.052 0 00-2.278 4.372zM19.25 3.953c.341 0 .589.016.744.047.124.992 0 2.016-.372 3.07-.496 1.52-1.472 2.992-2.93 4.418-1.673 1.706-3.657 2.993-5.95 3.86l-2.14-2.092c.93-2.326 2.232-4.31 3.906-5.954 1.24-1.24 2.51-2.14 3.813-2.697.992-.434 1.968-.652 2.93-.652zm0-1.953c-1.209 0-2.418.248-3.627.744-1.55.651-3.053 1.706-4.51 3.163-1.829 1.798-3.27 4.015-4.324 6.651a1.739 1.739 0 00-.093 1.116c.093.373.28.698.558.977l2.092 2.093c.403.403.868.605 1.395.605.217 0 .45-.047.698-.14a18.287 18.287 0 006.649-4.325c1.054-1.024 1.906-2.109 2.557-3.256.527-.93.899-1.892 1.116-2.884a8.261 8.261 0 00.232-2.279 5.95 5.95 0 00-.186-1.535l-.186-.558-.65-.186A7.503 7.503 0 0019.25 2zM14.6 9.395A1.996 1.996 0 0114.043 8c0-.558.186-1.023.558-1.395.402-.403.867-.605 1.394-.605.558 0 1.023.202 1.395.605.403.372.605.837.605 1.395 0 .527-.202.992-.605 1.395-.372.372-.837.558-1.395.558-.527 0-.992-.186-1.394-.558zM8.974 16.42L7.58 15.023l1.394 1.396zm-2.603 5.395l3.626-3.581a3.135 3.135 0 01-.976-.466l-4.045 4.047H6.37zm-4.185 0h1.395l4.742-4.698-1.44-1.395-4.697 4.698v1.395zm0-2.79l4.045-4.047A3.135 3.135 0 015.766 14l-3.58 3.628v1.395z"
      fill="currentColor"
    />
  </Icon>
);

RocketIcon.propTypes = {
  color: PropTypes.oneOf(colorOptions),
};
