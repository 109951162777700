// Factory function to create query keys in this specific format
// ['todos', 'list', { query: 'all' }]
// ['todos', 'list', { query: 'done' }]
// ['todos', 'detail', 'uuid']
// ['todos', 'detail', 'new']
// This also should create multiple nested keys for nested resources
// ['teams', 'detail', 'uuid', 'onCallSchedules', 'list', { query: 'all' }]
// ['teams', 'detail', 'uuid', 'onCallSchedules', 'detail', 'uuid']
// ['teams', 'detail', 'uuid', 'onCallSchedules', 'detail', 'new']
// For new resources, the final key should be 'new' rather than a UUID
// For list queries, the final key should be an object containing the filters
// This should also give you access to keys inside of a component without
// the need to construct a key manually
// TODO: Add handling for organizationId as the first key
export const createQueryKeyFactory = (path) => {
  const key = path.reduce((acc, segment) => {
    if (segment.id) {
      return [...acc, segment.resource, 'detail', segment.id];
    }
    return [...acc, segment.resource];
  }, []);

  return key;
};

// Validates a given key for equality with the list query key format, including nested keys
export function isListQueryKey(key) {
  // The last segment must be an object representing filters for a list query
  if (
    !Array.isArray(key) ||
    key.length < 3 ||
    typeof key[key.length - 1] !== 'object'
  ) {
    return false;
  }

  // Check if the second last segment is 'list', which is required for a list query key
  const listSegmentIndex = key.length - 2;
  if (key[listSegmentIndex] !== 'list') {
    return false;
  }

  // Additional checks can be added here for more specific validations (e.g., structure of filters)

  return true;
}

// Validates a given key for equality with the detail query key format, including nested keys
export function isDetailQueryKey(key) {
  // The last segment must be a string representing an ID for a detail query
  if (
    !Array.isArray(key) ||
    key.length < 3 ||
    typeof key[key.length - 1] !== 'string'
  ) {
    return false;
  }

  // Check if the second last segment is 'detail', which is required for a detail query key
  const detailSegmentIndex = key.length - 2;
  if (key[detailSegmentIndex] !== 'detail') {
    return false;
  }

  // Additional checks can be added here for more specific validations (e.g., format of the ID)

  return true;
}
