export const MILESTONE_OPTIONS = [
  {
    label: 'Started',
    value: 'started',
    type: 'started',
    active: true,
    helpText: 'When the affected system began having problems.',
    color: 'red',
  },
  {
    label: 'Detected',
    value: 'detected',
    type: 'detected',
    active: true,
    helpText:
      'When a monitoring system (or human) noticed that the system was having problems.',
    color: 'red',
  },
  {
    label: 'Acknowledged',
    value: 'acknowledged',
    type: 'acknowledged',
    active: true,
    helpText:
      'When someone responding to the incident acknowledged the situation.',
    color: 'orange',
  },
  {
    label: 'Investigating',
    value: 'investigating',
    type: 'investigating',
    active: true,
    helpText:
      'When the first concrete step toward triaging and identifying the problem was taken.',
    color: 'yellow',
  },
  {
    label: 'Identified',
    value: 'identified',
    type: 'identified',
    active: true,
    helpText: 'When the problem was identified and corrective actions began.',
    color: 'yellow',
  },
  {
    label: 'Mitigated',
    value: 'mitigated',
    type: 'mitigated',
    active: true,
    helpText:
      'When the system is no longer exhibiting problems to users, but the team is still monitoring the situation.',
    color: 'yellow',
  },
  {
    label: 'Resolved',
    value: 'resolved',
    type: 'resolved',
    active: false,
    helpText:
      'When the system is confirmed to be working again with no relapse.',
    color: 'green',
  },
  {
    label: 'Retrospective Started',
    value: 'postmortem_started',
    type: 'postmortem_started',
    active: false,
    helpText: 'When the team has begun the retrospective for an incident.',
    color: 'blue',
  },
  {
    label: 'Retrospective Completed',
    value: 'postmortem_completed',
    type: 'postmortem_completed',
    active: false,
    helpText:
      'When the team has finished reviewing the incident, clarified learnings and follow-ups, and published findings.',
    color: 'blue',
  },
  {
    label: 'Closed',
    value: 'closed',
    type: 'closed',
    active: false,
    helpText: 'System record indicating that all work has been completed',
    color: 'blue',
  },
];

export const LIFECYCLE_OPTIONS = [
  {
    label: 'Started',
    id: '123',
    milestones: [
      {
        label: 'Started',
        value: 'started',
        type: 'started',
        active: true,
        helpText: 'When the affected system began having problems.',
        color: 'red',
      },
    ],
  },
  {
    label: 'Incident Open',
    id: '123',
    milestones: [
      {
        label: 'Detected',
        value: 'detected',
        type: 'detected',
        active: true,
        helpText:
          'When a monitoring system (or human) noticed that the system was having problems.',
        color: 'red',
      },
      {
        label: 'Acknowledged',
        value: 'acknowledged',
        type: 'acknowledged',
        active: true,
        helpText:
          'When someone responding to the incident acknowledged the situation.',
        color: 'orange',
      },
      {
        label: 'Investigating',
        value: 'investigating',
        type: 'investigating',
        active: true,
        helpText:
          'When the first concrete step toward triaging and identifying the problem was taken.',
        color: 'yellow',
      },
      {
        label: 'Identified',
        value: 'identified',
        type: 'identified',
        active: true,
        helpText:
          'When the problem was identified and corrective actions began.',
        color: 'yellow',
      },
      {
        label: 'Mitigated',
        value: 'mitigated',
        type: 'mitigated',
        active: true,
        helpText:
          'When the system is no longer exhibiting problems to users, but the team is still monitoring the situation.',
        color: 'yellow',
      },
    ],
  },
  {
    label: 'Inactive Incident',
    id: '5555',
    milestones: [
      {
        label: 'Resolved',
        value: 'resolved',
        type: 'resolved',
        active: false,
        helpText:
          'When the system is confirmed to be working again with no relapse.',
        color: 'green',
      },
      {
        label: 'Retrospective Started',
        value: 'postmortem_started',
        type: 'postmortem_started',
        active: false,
        helpText: 'When the team has begun the retrospective for an incident.',
        color: 'blue',
      },
      {
        label: 'Retrospective Completed',
        value: 'postmortem_completed',
        type: 'postmortem_completed',
        active: false,
        helpText:
          'When the team has finished reviewing the incident, clarified learnings and follow-ups, and published findings.',
        color: 'blue',
      },
    ],
  },
  {
    label: 'Closed',
    id: '7777',
    milestones: [
      {
        label: 'Closed',
        value: 'closed',
        type: 'closed',
        active: false,
        helpText: 'System record indicating that all work has been completed',
        color: 'blue',
      },
    ],
  },
];

const closed = {
  label: 'Closed',
  type: 'closed',
  next: null,
  color: 'blue',
};
const postmortem_completed = {
  label: 'Retrospective completed',
  type: 'postmortem_completed',
  next: null,
  color: 'blue',
};
const postmortem_started = {
  label: 'Retrospective started',
  type: 'postmortem_started',
  next: null,
  color: 'blue',
};
const resolved = {
  label: 'Resolved',
  type: 'resolved',
  next: null,
  color: 'green',
};
const mitigated = {
  label: 'Mitigated',
  type: 'mitigated',
  next: resolved,
  color: 'yellow',
};
const identified = {
  label: 'Identified',
  type: 'identified',
  next: mitigated,
  color: 'yellow',
};
const investigating = {
  label: 'Investigating',
  type: 'investigating',
  next: identified,
  color: 'yellow',
};
const acknowledged = {
  label: 'Acknowledged',
  type: 'acknowledged',
  next: investigating,
  color: 'orange',
};
const detected = {
  label: 'Detected',
  type: 'detected',
  next: acknowledged,
  color: 'red',
};
const started = {
  label: 'Started',
  type: 'started',
  next: detected,
  color: 'red',
};

const lifecycleActiveMilestones = LIFECYCLE_OPTIONS.reduce((acc, curr) => {
  if (curr.label === 'Closed' || curr.label === 'Inactive Incident') return acc;
  curr.milestones.forEach((milestone) => {
    acc.push(milestone);
  });
  return acc;
}, []);

const lifecycleResolvedMilestones = LIFECYCLE_OPTIONS.reduce((acc, curr) => {
  if (curr.label === 'Started' || curr.label === 'Incident Open') return acc;
  curr.milestones.forEach((milestone) => {
    acc.push(milestone);
  });
  return acc;
}, []);

const lifecycleActiveOptions = LIFECYCLE_OPTIONS.reduce((acc, curr) => {
  if (curr.label === 'Closed') return acc;
  curr.milestones.forEach((milestone) => {
    acc.push(milestone.type);
  });
  return acc;
}, []);

export const ccActiveMilestones = {
  started,
  detected,
  acknowledged,
  investigating,
  identified,
  mitigated,
};

export const ccResolvedMilestones = {
  resolved,
  postmortem_started,
  postmortem_completed,
};

export const ccViewMilestones = {
  started,
  detected,
  acknowledged,
  investigating,
  identified,
  mitigated,
  resolved,
};

export const ccRetroMilestones = {
  started,
  detected,
  acknowledged,
  investigating,
  identified,
  mitigated,
  resolved,
  postmortem_started,
  postmortem_completed,
};

export const formattedMilestones = {
  started,
  detected,
  acknowledged,
  investigating,
  identified,
  mitigated,
  resolved,
  postmortem_started,
  postmortem_completed,
  closed,
};

export const resolvedMilestones = [
  resolved.type,
  postmortem_started.type,
  postmortem_completed.type,
];
