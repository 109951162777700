import React from 'react';

import {
  Box,
  Flex,
  Icon,
  Link,
  SkeletonText,
  Td,
  Text,
  Th,
  Tr,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { IoMdLock } from 'react-icons/io';

import { WatchButton } from '../../components';
import { IncidentMilestoneBadge } from '../incident-milestone-badge';
import { IncidentMSTeamsChannelLink } from '../incident-msteams-channel-link/IncidentMSTeamsChannelLink';
import { IncidentSeverityBadge } from '../incident-severity-badge';
import { IncidentSlackChannelLink } from '../incident-slack-channel-link';
import { IncidentStatusPageLink } from '../incident-status-page-link';
import { IncidentPropType } from '../prop-types';

export function IncidentListItem({
  incident,
  onToggleWatch,
  hideStatusPageLink = false,
  incidentLinkProps = {
    as: 'span',
  },
  renderAsOneCol = false,
  ...props
}) {
  const isLoading = !incident;
  return (
    <Tr
      display={renderAsOneCol ? 'flex' : 'table-row'}
      flexDirection="column"
      {...props}
    >
      <Td
        display={['block', null, 'table-cell']}
        whiteSpace="no-wrap"
        px="0 !important"
        pb={['1', 'null', '2']}
      >
        <IncidentSeverityBadge
          isLoading={isLoading}
          value={incident?.severity}
          color={incident?.severity_color}
        />
      </Td>
      <Th
        scope="row"
        minWidth="20ch"
        width="100%"
        display={renderAsOneCol ? 'flex' : 'table-cell'}
        px={renderAsOneCol ? '0 !important' : '3 !important'}
        py={renderAsOneCol ? '1' : '3'}
        position="relative"
        cursor="pointer"
      >
        <SkeletonText isLoaded={!isLoading} noOfLines={3} width="100%">
          <Box fontSize="sm" color="gray.700" mt="1" lineHeight="short">
            {incident?.isPrivate && (
              <Icon
                as={IoMdLock}
                color="gray.600"
                mr="2px"
                mt="-5px"
                boxSize="16px"
                verticalAlign="middle"
              />
            )}
            {incident?.id ? (
              <Link
                fontWeight="semibold"
                lineHeight="short"
                color="gray.800"
                _after={{
                  content: '" "',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
                {...incidentLinkProps}
              >
                {incident?.name}
              </Link>
            ) : (
              incident?.name
            )}
          </Box>
          <Box fontSize="xs" color="gray.600" fontWeight="normal">
            {incident?.number && <Text as="span">#{incident?.number}</Text>}{' '}
            {incident?.created}
          </Box>
        </SkeletonText>
      </Th>
      <Td px="0 !important" display={renderAsOneCol ? 'flex' : 'table-cell'}>
        <Flex
          gap="2"
          alignItems="center"
          width="100%"
          justifyContent={renderAsOneCol ? 'space-between' : 'flex-end'}
        >
          <Box textAlign="right">
            <IncidentMilestoneBadge isLoading={isLoading} width="100%" />
          </Box>
          <Flex alignItems="center" gap="2">
            <IncidentSlackChannelLink
              isLoading={isLoading}
              url={incident?.links?.slackChannel}
              preserveSpace={!renderAsOneCol}
            />
            <IncidentMSTeamsChannelLink
              isLoading={isLoading}
              url={incident?.links?.msTeamsChannel}
              preserveSpace={!renderAsOneCol}
            />
            {!hideStatusPageLink && (
              <IncidentStatusPageLink
                isLoading={isLoading}
                url={incident?.links?.privateStatusPage}
                preserveSpace={!renderAsOneCol}
              />
            )}
            <WatchButton
              isLoading={isLoading}
              isSelected={incident?.isWatched}
              onChange={onToggleWatch}
              id={incident?.id}
            />
          </Flex>
        </Flex>
      </Td>
    </Tr>
  );
}
IncidentListItem.propTypes = {
  incident: IncidentPropType,
  onToggleWatch: PropTypes.func,
  renderAsOneCol: PropTypes.bool,
  hideStatusPageLink: PropTypes.bool,
  incidentLinkProps: PropTypes.object,
};
