export const Menu = {
  baseStyle: {
    list: {
      padding: '0',
      bg: 'grey.0',
      border: '1px solid',
      borderColor: 'grey.300',
      borderRadius: 'base',
      '& > :first-of-type': {
        borderTopRightRadius: 'base',
        borderTopLeftRadius: 'base',
      },
      '& > :last-child': {
        borderBottomRightRadius: 'base',
        borderBottomLeftRadius: 'base',
      },
    },
    groupTitle: {
      cursor: 'default',
      margin: '0',
      py: 'mg2',
      px: 3,
      fontSize: 6,
      fontWeight: 6,
      lineHeight: 6,
      color: 'grey.700',
    },
    item: {
      py: 'mg2',
      px: 3,
      color: 'grey.900',
      display: 'flex',
      fontSize: 6,
      justifyContent: 'space-between',
      _active: {
        bg: 'gray.100',
        color: 'primary.700',
      },
      _hover: {
        boxShadow: 'none',
        bg: 'grey.100',
      },
      _disabled: {
        color: 'grey.500',
      },
      // overriding chakra defaults
      '.chakra-menu__icon-wrapper': {
        marginEnd: 2,
      },
    },
  },
};
