import React from 'react';

import { PostHogProvider } from 'posthog-js/react';
import { useBootstrapData } from 'queries/auth/bootstrap';

// We default to disabling session recording so that we can turn it on for
// a subset of users in the session replay wrapper
const posthogConfig = {
  api_host: 'https://app.posthog.com',
  disable_session_recording: true,
};

export const PosthogProvider = ({ children }) => {
  const { data, isLoading } = useBootstrapData();

  if (isLoading) {
    return children;
  }

  const POSTHOG_KEY = data?.data?.posthog_write_key;

  return (
    <PostHogProvider options={posthogConfig} apiKey={POSTHOG_KEY}>
      {children}
    </PostHogProvider>
  );
};
